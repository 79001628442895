import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, Button, CircularProgress} from "@mui/material";
import EditCategory from "./EditCategory";
import {useContext, useState} from "react";
import {Context} from "../../index";
import { ICategory } from '../../models/ICategory';

export interface SimpleCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    categoryData: ICategory
    setCategoryData: (category: ICategory) => void
}

function StyledCategoryDialog(props: SimpleCategoryDialogProps) {
    const {store} = useContext(Context)
    const {onClose, open, categoryData, setCategoryData} = props;
    const [isLoading, setLoading] = useState<boolean>(false)

    const deleteHandler = async () => {
        setLoading(true);
        await store.deleteCategory(categoryData._id).finally(() =>
          setLoading(false)
        );
        onClose();
      };      

    const submitHandler = async () => {
        setLoading(true)
        await store.updateCategory(categoryData).finally(() =>
            setLoading(false))
        onClose()
    }

    return (
        <Dialog onClose={onClose} open={open}>
            {isLoading && <Box sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '9999'
            }}>
                <CircularProgress/>
            </Box>}
            <DialogTitle>Edit or create Category</DialogTitle>
            <EditCategory category={categoryData} setCategory={setCategoryData}/>
            <Box sx={{display: 'flex', justifyContent: 'space-around'}} m={2}>
                <Button fullWidth variant='contained' sx={{marginRight: 2, backgroundColor:'#1976D2'}}
                        onClick={submitHandler}>Save</Button>
                <Button fullWidth variant='contained' color='error' onClick={deleteHandler} sx={{marginRight: 2, backgroundColor:'#FF0000'}}>Delete</Button>
                <Button fullWidth variant='contained' color='warning' onClick={onClose} sx={{backgroundColor:'#1976D2'}}>Cancel</Button>
            </Box>
        </Dialog>
    );
}

export default StyledCategoryDialog

import { IUser } from "../models/IUser";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import axios from 'axios';
import { AuthResponse } from "../models/response/AuthResponse";
import { API_URL } from "../http";
import UserService from "../services/UserService";
import { ICategory } from "../models/ICategory";
import 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';

export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = false;
    comment = '';
    clientName = '';
    selectedCategory = { name: 'All' } as ICategory;
    categories: ICategory[] = [];
    timers = {}; // Object to store timer values for each order and dish

    constructor() {
        makeAutoObservable(this);
    }

    // Set timer value for a specific order and dish
    setTimerValue(orderId, dishId, value) {
        if (!this.timers[orderId]) {
            this.timers[orderId] = {};
        }
        this.timers[orderId][dishId] = value;
    }

    // Get timer value for a specific order and dish
    getTimerValue(orderId, dishId) {
        return this.timers[orderId]?.[dishId] || null;
    }

    selectCategory(category: ICategory) {
        this.selectedCategory = category
    }

    setCategories(categories: ICategory[]) {
        this.categories = categories;
    }


    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setComment(str: string) {
        this.comment = str;
    }
    setClientName(str: string) {
        this.clientName = str;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }


    async isWorkTime() {
        try {
            // Fetch work times for the current day from the TimeTable
            const response = await UserService.fetchWorkTimes();
            const workTimes = response.data; // Extract data from the response
    
            // Find the work time for the current day
            const today = new Date();
            const todayWorkTime = workTimes.find((workTime) =>
                dayjs(workTime.date).isSame(today, 'day')
            );
    
            if (!todayWorkTime) {
                console.warn('Work time not available for today. Using default work time.');
    
                // Fetch default work times
                const defaultResponse = await UserService.fetchDefaultWorkTimes();
                const defaultWorkTimes = defaultResponse.data;
    
                // Find the default work time for the current day
                const defaultTodayWorkTime = defaultWorkTimes.find((defaultWorkTime) =>
                    dayjs(today).format('dddd').toLowerCase() === defaultWorkTime.day.toLowerCase()
                );
    
                // Log the found default work time
                console.log('Found default work time for today:', defaultTodayWorkTime);
    
                if (!defaultTodayWorkTime) {
                    console.error('Default work time not available for today.');
                    return false;
                }
    
                // Extract start and end times for the default work time
                const defaultWorkStartTime = dayjs(defaultTodayWorkTime.startTime, 'HH:mm');
                const defaultWorkEndTime = dayjs(defaultTodayWorkTime.endTime, 'HH:mm');
    
                // Check if the current time is within the work time range
                const currentTime = dayjs();
    
                // Log the current and default work times for debugging
                console.log('Using default work time:');
                console.log('Current time:', currentTime.format('HH:mm'));
                console.log('Default work start time:', defaultWorkStartTime.format('HH:mm'));
                console.log('Default work end time:', defaultWorkEndTime.format('HH:mm'));
    
                const isWithinDefaultWorkTime = currentTime.isBetween(
                    defaultWorkStartTime,
                    defaultWorkEndTime,
                    undefined,
                    '[]'
                );
    
                // Log the result
                console.log('Is within default work time:', isWithinDefaultWorkTime);
    
                return isWithinDefaultWorkTime;
            }
    
            // Extract start and end times for the current day
            const workStartTime = dayjs(todayWorkTime.startTime, 'HH:mm');
            const workEndTime = dayjs(todayWorkTime.endTime, 'HH:mm');
    
            // Check if the current time is within the work time range
            const currentTime = dayjs();
    
            // Log the current and work times for debugging
            console.log('Current time:', currentTime.format('HH:mm'));
            console.log('Work start time:', workStartTime.format('HH:mm'));
            console.log('Work end time:', workEndTime.format('HH:mm'));
    
            const isWithinWorkTime = currentTime.isBetween(workStartTime, workEndTime, undefined, '[]');
    
            // Log the result
            console.log('Is within work time:', isWithinWorkTime);
    
            return isWithinWorkTime;
    
        } catch (e) {
            console.error('Error checking work time:', e.response?.data?.message);
            return false;
        }
    }
    

    async updateCategory(category) {
        try {
            const response = await UserService.updateCategory(category)
            return true
        }
        catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async deleteCategory(categoryId: string) {
        try {
            const response = await UserService.deleteCategory(categoryId);
            return true;
        } catch (e) {
            console.log(e.message);
            return false;
        }
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async updateDish(dish) {
        try {
            const response = await UserService.updateDish(dish)
            return true
        }
        catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async updateDishes(dishes) {
        try {
            const response = await UserService.updateDishes(dishes)
            return true
        }
        catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async registration(email: string, password: string) {
        try {
            const response = await AuthService.registration(email, password);
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({} as IUser);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, { withCredentials: true })
            console.log(response);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }

}

import React, {createContext} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import Store from "./store/store";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import './index.css'
import {theme} from "./theme";
import { I18nextProvider } from 'react-i18next';
import i18n from './components/translate/i18n';


interface State {
    store: Store,
}

export const store = new Store();

export const Context = createContext<State>({
    store,
})
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Context.Provider value={{
                store
            }}>
                <I18nextProvider i18n={i18n}>
                    <App/>
                </I18nextProvider>
            </Context.Provider>
        </ThemeProvider>
    </BrowserRouter>
);


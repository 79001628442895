import * as React from 'react';
import { useContext, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Container from '@mui/material/Container';
import {
    Accordion,
    AccordionDetails, AccordionSummary,
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper, Tooltip,
    Typography
} from "@mui/material";
import UserService from "../../services/UserService";
import { IDish } from "../../models/IDish";
import { OrderStatus } from "../../models/IOrder";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import StyledDialog from "./Dialog";
import Categories from "../../components/categories/Categorylist";
import { InfoOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import OrderPage from "../OrderPage";
import { Desktop, TabletAndBelow } from "../../layout/Responsive";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import ClientExtrasMenu from '../../components/extras/ClientExtrasMenu';

function ClientPage() {
    const { comment } = useParams()
    const { clientName } = useParams()
    const [comments, setComments] = useState(comment)
    const [clientNames, setClientNames] = useState(clientName)

    const { tableNumber } = useParams()
    const [table, setTable] = useState(tableNumber)
    const { store } = useContext(Context)
    const [dishes, setDishes] = React.useState<IDish[]>([]);
    const [selectedDishes, setSelectedDishes] = React.useState<IDish[]>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [refreshOrders, setRefreshOrders] = React.useState(false);
    const [animate, setAnimate] = useState(false)
    const [isActiveElem, setActiveElem] = React.useState<IDish>(null)
    const [openTooltipIndex, setOpenTooltipIndex] = React.useState<IDish>(null);
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);

    const handleClickTooltip = (dish) => {
        if (dish !== openTooltipIndex) {
            setOpenTooltipIndex(dish)
        }
        if (dish === openTooltipIndex) {
            setOpenTooltipIndex(null)
        }
    }

    const handleClick = (dish) => {

        if (dish !== isActiveElem) {
            setActiveElem(dish)
        }
        if (dish === isActiveElem) {
            setActiveElem(null)
        }
    }
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const addDish = async (dish) => {
        try {
            // Check if it's within work hours
            const isWorkTime = await store.isWorkTime();
            console.log(isWorkTime, 'isWorkTimeClientPage'); // Add this line for debugging

            if (isWorkTime === undefined) {
                console.error('isWorkTime returned undefined.'); // Add this line for debugging
                return;
            }

            if (!isWorkTime) {
                console.error('Cannot add dish outside of work hours.');
                // Set an error message for the user
                setErrorMessage('Cannot add dish outside of work hours.');
                return;
            }

            // Reset the error message if it was previously set
            setErrorMessage(null);

            const dishes = [...selectedDishes];
            if (dishes.length) {
                const dishIndex = dishes.findIndex((d) => d.name === dish.name);
                if (dishIndex >= 0) {
                    dishes[dishIndex].count++;
                } else {
                    const newDish = { ...dish, count: 1 };
                    dishes.push(newDish);
                }
            } else {
                dishes.push({ ...dish, count: 1 });
            }

            setSelectedDishes(dishes);

            // Log the updated dishes
            console.log('Selected dishes:', dishes);
        } catch (error) {
            console.error('Error adding dish:', error);
        }
    };


    const getDishesTotalPrice = () => {
        const totalPrice = selectedDishes.reduce((prev, curr) => prev + curr.count * curr.price, 0)
        return totalPrice ? Math.round(totalPrice) : 0
    }

    const deleteDish = dish => {
        const dishes = [...selectedDishes]
        const dishIndex = dishes.findIndex(d => d.name === dish.name)

        dishes.splice(dishIndex, 1)
        setSelectedDishes(dishes)
    }

    async function getDishes() {
        try {
            const response = await UserService.fetchDishes();
            setDishes(response.data.filter(dish => !dish.isBlocked));
        } catch (e) {
            console.log(e);
        }
    }

    const filterCategories = d => {
        if (store.selectedCategory.name === "All") return true;
        return d?.category?.name === store.selectedCategory.name;
    };

    const createOrder = async () => {

        const dishesCount = selectedDishes.map(d => d.count);
        const dishesCategory = selectedDishes.map(d => d.category);
        const dishesCancel = selectedDishes.map(() => false);
        const dishesDone = selectedDishes.map(() => false);
        const dishEndTimes = selectedDishes.map(() => null);

        const order = {
            dishes: selectedDishes,
            dishesCount,
            dishesCancel,
            dishesDone,
            dishEndTimes,
            paid: '',
            dishesCategory,
            totalPrice: getDishesTotalPrice(),
            discounts: [{ name: '', price: 0 }],
            clientName: clientNames,
            comment: comments,
            manager: store.user.id,
            status: OrderStatus.created,
            tableNumber: +table
        };

        await UserService.createOrder(order);
        setRefreshOrders(true);
        setSelectedDishes([]);
    };

    useEffect(() => {
        getDishes()
    }, [])

    const nodeRef = useRef(null)
    const defaultStyle = {
        marginBottom: '20px',
        position: 'sticky',
        top: '70px',
        zIndex: '1',
        transition: `boxShadow 100ms ease-in-out`,
        boxShadow: 'none'

    }

    const transitionStyles = {
        entering: { boxShadow: '5px 5px 9px #1976D2', backgroundColor: '#1976D2', color: '#ffffff' },
        entered: { boxShadow: 'none' },
        exiting: { boxShadow: '5px 5px 9px #FF0000', backgroundColor: '#FF0000', color: '#ffffff' }, // Red color for exiting state
        exited: { boxShadow: 'none' },
    };

    const dragItem = useRef<IDish>()

    const dragOver = (e, item: IDish) => {
        dragItem.current = item
    }

    async function drop(e, item: IDish) {
        const dropItem = dragItem.current
        const startItem = item
        if (store.isAuth) {

            if (!startItem || !dropItem) return

            const copyListItems = dishes.map(dish => {
                if (dish._id === startItem._id) {
                    return { ...dish, order: dropItem.order }
                }
                else if (dish._id === dropItem._id) {
                    return { ...dish, order: startItem.order }
                }
                else return { ...dish }
            })
            dragItem.current = null
            setDishes(copyListItems)
            await UserService.updateDishes(copyListItems)
        }
    }

    return (
        <Container disableGutters maxWidth="xl" component="main">
            <StyledDialog
                open={openDialog}
                onClose={handleCloseDialog}
                createOrder={createOrder}
                tableNumber={table}
                setTableNumber={(num: string | number) => setTable(num.toString())}
                comment={comments}
                setComment={setComments}
                clientName={clientNames}
                setClientName={setClientNames}
            />
            <Box sx={{
                display: 'flex',
            }}>
                <Desktop>

                    <Box sx={{ flexGrow: 0, minWidth: '20%', margin: '20px', marginRight: 0, }}>
                        <Paper elevation={3} sx={{ padding: 2, }}>
                            <List>
                                {selectedDishes?.length ? selectedDishes.map(dish => (<ListItem
                                    key={dish._id}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteDish(dish)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar src={dish.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={dish.name}
                                        secondary={`${dish.price}${t('LKR')} x ${dish.count} = ${Math.round(dish.count * dish.price)}${t('LKR')}`}
                                    />
                                </ListItem>)) : <Typography variant="body1">{t('Please select something')}</Typography>}
                                {errorMessage && (
                                    <div style={{ color: 'red', marginTop: '10px' }}>
                                        {errorMessage}
                                    </div>
                                )}
                            </List>
                            <Divider />
                            <Typography variant={'h4'}>{t('Total')}: {getDishesTotalPrice()}{t('LKR')}</Typography>
                            <React.Suspense fallback={<div>Loading...</div>}>

                                <Button
                                    sx={{ backgroundColor: '#1976D2' }}
                                    variant="contained"
                                    disabled={!selectedDishes?.length}
                                    fullWidth
                                    onClick={handleOpenDialog}>Create
                                    Order</Button>
                            </React.Suspense>

                        </Paper>
                        <OrderPage refresh={refreshOrders} setRefresh={setRefreshOrders} isClient={true} />
                    </Box>
                    <Box sx={{ flexGrow: 1, minWidth: '70%', margin: '20px', height: '100%' }}>
                        <Box mb={3}>
                            <Categories />
                        </Box>
                        <Box>
                            <Paper elevation={3}>
                                <Box p={3} sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    maxHeight: '100%',
                                    overflow: 'auto',
                                }}>
                                    {dishes.length && dishes.filter(filterCategories).sort((a, b) => a.order - b.order).map((dish) => (
                                        <Box
                                            draggable={store.isAuth}
                                            key={dish._id}
                                            onDragOver={(e) => dragOver(e, dish)}
                                            onDragEnd={(e) => drop(e, dish)}
                                            sx={{ margin: '10px', }}>
                                            <Card sx={{ width: 250, position: 'relative', height: '310px' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        position: 'absolute',
                                                        justifyContent: 'space-between',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                    }}>
                                                    <div style={{
                                                        backgroundColor: '#D27919',
                                                        padding: '5px 15px',
                                                        color: '#fff',
                                                        display: isActiveElem === dish ? 'none' : '',

                                                    }}>{dish.price}{t('LKR')}
                                                    </div>
                                                    <div style={{
                                                        backgroundColor: '#D27919',
                                                        padding: '5px 15px',
                                                        color: '#fff',
                                                        display: isActiveElem === dish ? 'none' : ''
                                                    }}>{Math.ceil(dish.time / 60)} {t('min')}
                                                    </div>
                                                </div>
                                                <CardMedia
                                                    component="img"
                                                    alt="dish"
                                                    height="150"
                                                    image={dish.image}
                                                    onClick={() => { handleClick(dish) }}
                                                    sx={{ height: isActiveElem === dish ? '300px' : '150px' }}
                                                />
                                                <CardContent>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        heigth: '250px'
                                                    }}>
                                                        <Typography variant="h5" component="div">
                                                            {i18n.language === 'uk' && dish.nameTranslation
                                                                ? `${dish.nameTranslation}`
                                                                : `${dish.name}`}
                                                        </Typography>
                                                        <Tooltip
                                                            open={openTooltipIndex === dish}
                                                            onClose={() => setOpenTooltipIndex(null)}
                                                            title={
                                                                i18n.language === 'uk'
                                                                    ? `Складові: ${dish.ingredientsTranslation.join(', ')}`
                                                                    : `Ingredients: ${dish.ingredients.join(', ')}`
                                                            }>
                                                            <IconButton onClick={() => handleClickTooltip(dish)}>
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <ClientExtrasMenu 
                                                            dish={dish}
                                                            onAddExtras={addDish}
                                                        />
                                                    </Box>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        sx={{ backgroundColor: '#1976D2' }}
                                                        size="small"
                                                        style={{ position: 'absolute', bottom: 7, right: 5, width: '240px' }}
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => {
                                                            addDish(dish)
                                                        }}>{t('ADD')}</Button>
                                                </CardActions>
                                            </Card>
                                        </Box>))
                                    }
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Desktop>
                <TabletAndBelow>
                    <Box sx={{ flexGrow: 1, height: '100%', }}>
                        <Box

                            sx={{
                                position: 'sticky',
                                top: '0',
                                zIndex: '1',
                                backgroundColor: '#FFFFFF',
                                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
                                padding: '10px',
                                width: '100vw',
                                height: '70px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Categories />
                        </Box>
                        <Transition
                            nodeRef={nodeRef}
                            in={animate}
                            timeout={1000}>
                            {animate => (<Accordion
                                ref={nodeRef}
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[animate]
                                }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{t('Your Order')} ({selectedDishes?.length})</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ flexGrow: 0, marginBottom: '20px' }}>
                                        <Paper elevation={3} sx={{ padding: 2 }}>
                                            <List>
                                                {selectedDishes?.length ? selectedDishes.map(dish => (<ListItem
                                                    key={dish._id}
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete"
                                                            onClick={() => deleteDish(dish)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar src={dish.image} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={dish.name}
                                                        secondary={`${dish.price}${t('LKR')} x ${dish.count} = ${Math.round(dish.count * dish.price)}${t('LKR')}`}
                                                    />
                                                </ListItem>)) :
                                                    <Typography variant="body1">{t('Please select something')}</Typography>}
                                                {errorMessage && (
                                                    <div style={{ color: 'red', marginTop: '10px' }}>
                                                        {errorMessage}
                                                    </div>
                                                )}
                                            </List>
                                            <Divider />
                                            <Typography
                                                variant={'h4'}>{t('Total')}: {getDishesTotalPrice()}{t('LKR')}</Typography>
                                            <React.Suspense fallback={<div>Loading...</div>}>

                                                <Button
                                                    sx={{ backgroundColor: '#1976D2' }}
                                                    variant="contained"
                                                    disabled={!selectedDishes?.length}
                                                    fullWidth
                                                    onClick={handleOpenDialog}>Create
                                                    Order</Button>
                                            </React.Suspense>
                                        </Paper>
                                        <OrderPage refresh={refreshOrders} setRefresh={setRefreshOrders} isClient={true} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>)}

                        </Transition>
                        <Box>
                            <Paper elevation={3}>
                                <Box p={3} sx={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    flexWrap: 'wrap',
                                    maxHeight: '100%',
                                    overflow: 'auto'
                                }}>
                                    {dishes.length && dishes.filter(filterCategories).sort((a, b) => a.order - b.order).map((dish) => (
                                        <Box
                                            draggable={store.isAuth}
                                            key={dish._id}
                                            onDragOver={(e) => dragOver(e, dish)}
                                            onDragEnd={(e) => drop(e, dish)}
                                            sx={{ margin: '10px' }}>
                                            <Card sx={{ width: 250, position: 'relative' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        position: 'absolute',
                                                        justifyContent: 'space-between',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0
                                                    }}>
                                                    <div style={{
                                                        backgroundColor: '#D27919',
                                                        padding: '5px 15px',
                                                        color: '#fff'
                                                    }}>{dish.price}{t('LKR')}
                                                    </div>
                                                    <div style={{
                                                        backgroundColor: '#D27919',
                                                        padding: '5px 15px',
                                                        color: '#fff'
                                                    }}>{Math.ceil(dish.time / 60)} {t('min')}
                                                    </div>
                                                </div>
                                                <CardMedia
                                                    component="img"
                                                    alt="dish"
                                                    height="150"
                                                    image={dish.image}
                                                />
                                                <CardContent>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Typography variant="h5" component="div">
                                                            {i18n.language === 'uk' && dish.nameTranslation
                                                                ? `${dish.nameTranslation}`
                                                                : `${dish.name}`}
                                                        </Typography>
                                                        <Tooltip
                                                            open={openTooltipIndex === dish}
                                                            onClose={() => setOpenTooltipIndex(null)}
                                                            title={
                                                                i18n.language === 'uk'
                                                                    ? `Складові: ${dish.ingredientsTranslation.join(', ')}`
                                                                    : `Ingredients: ${dish.ingredients.join(', ')}`
                                                            }>
                                                            <IconButton onClick={() => handleClickTooltip(dish)}>
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <ClientExtrasMenu 
                                                            dish={dish}
                                                            onAddExtras={addDish}
                                                        />
                                                    </Box>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        sx={{ backgroundColor: '#1976D2' }}
                                                        size="small"
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => {
                                                            addDish(dish);
                                                            setAnimate(!animate);
                                                        }}>{t('ADD')}</Button>
                                                </CardActions>
                                            </Card>
                                        </Box>))
                                    }
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </TabletAndBelow>
            </Box>
        </Container>
    );
}

export default observer(ClientPage)
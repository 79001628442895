import React, { useContext, Suspense } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button, TextField } from "@mui/material";
import { Context } from "../../index";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  createOrder: () => void;
  tableNumber: string;
  setTableNumber: (num: string | number) => void;
  clientName: string;
  setClientName: (str: string) => void;
  comment: string;
  setComment: (str: string) => void;
}

function StyledDialog(props: SimpleDialogProps) {
  const {
    onClose,
    open,
    createOrder,
    tableNumber,
    setTableNumber,
    comment,
    clientName,
    setComment,
    setClientName
  } = props;
  const { store } = useContext(Context);

  const submitHandler = () => {
    createOrder();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{!store.isAuth ? 'Is that all?' : 'Select table'}</DialogTitle>

      <Box
        component="form"
        sx={{
          display: 'flex',
          width: '80%',
          m: 'auto',
          rowGap: '10px',
          flexDirection: 'column'
        }}
        mb={2}
        noValidate
        autoComplete="off"
      >
        {store.isAuth && (
         <TextField
         id="outlined-number"
         label="Table Number"
         variant="outlined"
         type="number"
         required
         value={tableNumber}
         onChange={e => setTableNumber(e.target.value)}
       />
        )}
        {store.isAuth ? (
          <>
            <TextField
              required
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              id="filled-multiline-static"
              label="Write your Name"
              rows={1}
              defaultValue=""
              variant="outlined"
            />
            <TextField
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              id="filled-multiline-static-1"
              label="Write your comment for kitchen here if you like"
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
            />
          </>
        ) : (
          <>
            <TextField
              required
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              id="filled-multiline-static-1"
              label="Write your Name"
              rows={1}
              defaultValue=""
              variant="outlined"
            />
            <TextField
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              id="filled-multiline-static"
              label="Write your comment for kitchen here if you like"
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
            />
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }} m={2}>
        <Button sx={{ opacity:'0.8' }} variant='contained' color='warning' onClick={onClose}>
          Go Back
        </Button>
        <Suspense fallback={<div>Loading...</div>}>
          <Button
            disabled={!tableNumber || !clientName}
            variant='contained'
            sx={{ marginLeft: 1, backgroundColor: '#1976D2' }}
            onClick={submitHandler}
          >
            Create Order
          </Button>
        </Suspense>
      </Box>
    </Dialog>
  );
}

export default StyledDialog;

import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import { ICategory } from "../../models/ICategory";
import UserService from "../../services/UserService";
import { Box, Chip, Button } from "@mui/material";
import AddCategoryButton from "./AddCategoryButton";
import StyledCategoryDialog from "./CategoryDialog";
import { useTranslation } from 'react-i18next';

const emptyCategoryData = {
  name: '',
  order: 0,
};

export default observer(function Categories() {
  const { store } = useContext(Context);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [categoryData, setCategoryData] = useState<ICategory>(emptyCategoryData);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
  const [draggedCategory, setDraggedCategory] = useState<ICategory | null>(null);
  const [isDndModeOn, setDndModeOn] = useState(false);
  const { t } = useTranslation();

  const handleOpenDialog = (item) => {
    setCategoryData(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCategoryData(emptyCategoryData);
    setDraggedCategory(null);
    getCategories();
  };

  const handleDragStart = (e, category) => {
    if (isDndModeOn && store.isAuth) {
      setDraggedCategory(category);
      e.dataTransfer.effectAllowed = "move";
    } else {
      e.preventDefault();
    }
  };

  const handleDragEnter = (e, category) => {
    if (isDndModeOn && store.isAuth) {
      e.preventDefault();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, targetCategory) => {
    e.preventDefault();
    if (draggedCategory && targetCategory && draggedCategory._id !== targetCategory._id) {
      const updatedCategories = [...categories];
      const draggedIndex = updatedCategories.findIndex((category) => category._id === draggedCategory._id);
      const targetIndex = updatedCategories.findIndex((category) => category._id === targetCategory._id);
  
      [updatedCategories[draggedIndex].order, updatedCategories[targetIndex].order] =
        [targetCategory.order, draggedCategory.order];
  
      setCategories(updatedCategories);
      setDraggedCategory(null);
  
      // Save the updated order on the server
      try {
        await UserService.updateCategoriesOrder(updatedCategories);
  
        // Introduce a delay before fetching the updated categories
        setTimeout(async () => {
          // Fetch the updated categories after a short delay
          const response = await UserService.fetchCategories();
          setCategories(response.data);
        }, 300); // Adjust the delay as needed
      } catch (error) {
        console.error("Error updating categories order:", error);
        // Handle error as needed
      }
    }
  };
  

  const toggleDndMode = () => {
    setDndModeOn(!isDndModeOn);
  };

  async function getCategories() {
    try {
      const response = await UserService.fetchCategories();
      setCategories(response.data);
    } catch (e) {
      console.error("Error fetching categories:", e);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <StyledCategoryDialog
        open={openDialog}
        onClose={handleCloseDialog}
        categoryData={categoryData}
        setCategoryData={setCategoryData}
      />
      
      <Box
        sx={{ display: 'flex', overflowX: 'auto' , alignItems: 'center', gap: '10px', transition: 'transform 0.2s ease-in-out' }}
        onDragOver={handleDragOver}
      >
        {store.isAuth && (
          <Button
            variant="outlined"
            onClick={toggleDndMode}
            sx={{ margin: '10px' }}
          >
            {isDndModeOn ? "Off DnD" : "On DnD"}
          </Button>
        )}
        {store.isAuth && <AddCategoryButton openModal={() => handleOpenDialog(emptyCategoryData)} />}
        {categories.map((category, index) => (
          <div
            key={category._id}
            draggable={isDndModeOn && store.isAuth}
            onDragStart={(e) => handleDragStart(e, category)}
            onDragEnter={(e) => handleDragEnter(e, category)}
            onDrop={(e) => handleDrop(e, category)}
          >
            <Chip
              label={t(category.name)}
              variant="outlined"
              sx={{
                p: 2,
                bgcolor: '#F5F5F5',
                position: 'relative',
                cursor: store.isAuth && isDndModeOn ? 'grab' : 'default',
              }}
              onClick={() => {
                if (store.isAuth && selectedCategory && selectedCategory.name === category.name) {
                  handleOpenDialog(category);
                } else {
                  setSelectedCategory(category);
                  store.selectCategory(category);
                }
              }}
              color={selectedCategory && selectedCategory.name === category.name ? "primary" : "default"}
            />
          </div>
        ))}
      </Box>
    </>
  );
});

import {Outlet} from "react-router-dom";
import * as React from "react";
import Bar from "../components/Bar";
import {Box} from "@mui/material";

const Layout = () => {
    return (
        <Box sx={{
            height: '100%',
            minHeight: '100%'
        }}>
            <Bar/>
            <Box>
                <Outlet/>
            </Box>
        </Box>
    );
}

export default Layout
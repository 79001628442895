import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, ButtonGroup } from '@mui/material';
import UserService from '../../services/UserService';
import { store } from '../..';

const LanguageSwitchButton = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLanguageSwitch = async (newLanguage) => {
    setLoading(true);

    try {
      const response = await UserService.fetchTranslation(newLanguage);
      const translationData = response.data;
      // Update the i18n resources with the new translation data
      i18n.addResourceBundle(newLanguage, 'translation', translationData, true, true);
      i18n.changeLanguage(newLanguage);
    } catch (error) {
      console.error('Failed to fetch translation:', error);
    }

    setLoading(false);
  };

  const handleEnButtonClick = () => {
    handleLanguageSwitch('en');
  };

  const handleUkButtonClick = () => {
    handleLanguageSwitch('uk');
  };

  const buttonGroupStyle = {
    position: 'absolute',
    top: '20px',
    ...(isDesktop
      ? { right: store.isAuth ? '360px' : '10px' } 
      : { right: '0px' })
  };

  return (
    <ButtonGroup variant="text" color="primary" sx={buttonGroupStyle}>
      <Button
        onClick={handleEnButtonClick}
        disabled={loading || i18n.language === 'en'}
      >
        🇺🇸 {/* USA flag emoji */}
      </Button>
      <Button
        onClick={handleUkButtonClick}
        disabled={loading || i18n.language === 'uk'}
      >
        🇺🇦 {/* Ukrainian flag emoji */}
      </Button>
    </ButtonGroup>
  );
};

export default LanguageSwitchButton;

import * as React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useContext} from "react";
import {Context} from "../../index";
import {PublicRoutes} from "../../constants/PublicRoutes";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const {store} = useContext(Context)
    const location = useLocation();

    if (!store.isAuth) {
        return <Navigate to={PublicRoutes.LOGIN} state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth
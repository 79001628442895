import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import CSVDownload from "../../components/CSVDownload";
import UserService from "../../services/UserService";
import {IOrder} from "../../models/IOrder";

export interface IOrderCSV {
    orderId: string;
    table: number;
    dishName: string;
    dishCount: number;
    dishPrice: number;
    dishesPrice: number;
    orderTime: string;
    dishStatus: string;
    dishPaid: string;
    clientName: string;
    comment: string;
    dishCategory: string;
}

export const mutateData = (orders: IOrder[]) =>{
    const mutatedOrders: IOrderCSV[] = []
    orders.forEach(order=>{
        order.dishes.forEach((dish, idx)=>{
            const orderCSV = {} as IOrderCSV
            orderCSV.orderId = order._id
            orderCSV.table = order.tableNumber
            orderCSV.dishName = dish.name
            orderCSV.dishCount = order.dishesCount[idx]
            orderCSV.dishPrice = dish.price
            orderCSV.dishesPrice = dish.price * order.dishesCount[idx]
            orderCSV.orderTime = order.createdAt
            orderCSV.dishStatus = order.dishesCancel[idx] ? 'cancel' : order.dishesDone[idx] ? 'complete' : 'not complete'
            orderCSV.dishPaid = order.paid
            orderCSV.clientName = order.clientName
            orderCSV.comment = order.comment
            orderCSV.dishCategory = order.dishesCategory && order.dishesCategory[idx] ? order.dishesCategory[idx].name : '';

            mutatedOrders.push(orderCSV)
        })
    })
    return mutatedOrders
}

function Orders() {
    const [orders, setOrders] = useState<IOrderCSV[]>(null)
    useEffect(()=>{
        updateOrders()
    }, [])

    const updateOrders = async ()=>{
        const orders = await UserService.getOrdersCSV()

        setOrders(mutateData(orders.data))
    }

    return (
        <Box>
            {orders ? <CSVDownload data={orders} /> : <Typography>No data</Typography>}
        </Box>
    );
}

export default Orders;
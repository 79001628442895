import React, { useState, useEffect } from 'react';
import { IconButton, Box, Card, CardMedia, CardContent, Typography, Button, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IDish } from '../../models/IDish';
import UserService from '../../services/UserService';
import { t } from 'i18next';

interface ClientExtrasMenuProps {
  dish: IDish;
  onAddExtras: (extras: IDish) => void;
}

const ClientExtrasMenu: React.FC<ClientExtrasMenuProps> = ({ dish, onAddExtras }) => {
  const [open, setOpen] = useState(false);
  const [extras, setExtras] = useState<IDish[]>([]);

  useEffect(() => {
    const fetchDishExtras = async () => {
      if (dish.extras && dish.extras.length > 0) {
        try {
          const fullExtras = await fetchDishesByIds(dish.extras);
          setExtras(fullExtras);
        } catch (error) {
          console.error('Error fetching dish objects by IDs:', error);
        }
      }
    };

    fetchDishExtras();
  }, [dish.extras]);

  const fetchDishesByIds = async (dishIds: string[]) => {
    try {
      const response = await UserService.fetchDishesByIds(dishIds);
      return response.data;
    } catch (error) {
      console.error('Error fetching dish objects by IDs:', error);
      throw error;
    }
  };

  const handleAddExtra = (extra: IDish) => {
    onAddExtras(extra);
  };

  return (
    <>
      {extras.length > 0 && (
        <IconButton onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      )}
      {open && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.1)',
              zIndex: 9999,
            }}
            onClick={() => setOpen(false)}
          />
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10000,
              maxHeight: '80vh', // Adjust the maximum height as needed
              overflowY: 'auto', // Allow vertical scrolling
            }}
          >
            <Card sx={{ width: 300, margin: 1, textAlign: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  {`Add Extras for ${dish.name}`}
                </Typography>
                {extras && extras.length > 0 ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {extras.map((extra) => (
                      <Grow key={extra._id} in={true}>
                        <Card sx={{ width: '90%', margin: 1, textAlign: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                          <CardMedia component="img" height="150" image={extra.image} alt={extra.name} />
                          <CardContent>
                            <Typography variant="h6" component="div" gutterBottom>
                              {extra.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              {`${extra.price} ${t('LKR')}`}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={() => handleAddExtra(extra)}>
                              Add
                            </Button>
                          </CardContent>
                        </Card>
                      </Grow>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body1">No extras available for this dish.</Typography>
                )}
              </CardContent>
            </Card>
          </Box>

        </>
      )}
    </>
  );
};

export default ClientExtrasMenu;

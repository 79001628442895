import { useMediaQuery } from "react-responsive"

const useDesktopMediaQuery = () =>
    useMediaQuery({ query: "(min-width: 1280px)" })

const useTabletAndBelowMediaQuery = () =>
    useMediaQuery({ query: "(max-width: 1279px)" })

    
const useNotMobileMediaQuery = () =>
    useMediaQuery({ query: "(min-width: 628px)" })

const useMobileMediaQuery = () =>
    useMediaQuery({ query: "(max-width: 627px)" })

export const Desktop = ({ children }) => {
    const isDesktop = useDesktopMediaQuery()

    return isDesktop ? children : null
}

export const TabletAndBelow = ({ children }) => {
    const isTabletAndBelow = useTabletAndBelowMediaQuery()

    return isTabletAndBelow ? children : null
}

export const NotMobile = ({ children }) => {
    const isNotMobile = useNotMobileMediaQuery()

    return isNotMobile ? children : null
}

export const Mobile = ({ children }) => {
    const isMobile = useMobileMediaQuery()

    return isMobile ? children : null
}

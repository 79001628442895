import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DishesList from "./DishesList";
import Orders from "./Orders";
import Bills from './Bills';
import TreeOrders from './TreeOrders'
import TimeTable from './TimeTable';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AdminPageContent() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    sx={{display:'flex'}}
                    value={value} 
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Dishes" {...a11yProps(0)} />
                    <Tab label="Orders" {...a11yProps(1)} />
                    <Tab label="Bills" {...a11yProps(2)} />
                    <Tab label="Tree Orders" {...a11yProps(3)} />
                    <Tab label="TimeTable" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box>
                    <DishesList/>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box>
                    <Orders />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box>
                    <Bills />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box>
                    <TreeOrders />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Box>
                    <TimeTable />
                </Box>
            </TabPanel>
        </Box>
    );
}

export default AdminPageContent;


import * as React from "react";
import {useContext, useState} from "react";
import {Context} from "../index";
import {IUser} from "../models/IUser";
import UserService from "../services/UserService";
import {useNavigate} from "react-router-dom";
import {PublicRoutes} from "../constants/PublicRoutes";

const ProtectedPage = () => {
    const {store} = useContext(Context)
    const [users, setUsers] = useState<IUser[]>([]);
    const navigate = useNavigate()

    async function getUsers() {
        try {
            const response = await UserService.fetchUsers();
            setUsers(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    const logout = async () => {
        await store.logout()
        navigate(PublicRoutes.HOME_DIRECTORY, { replace: true })
    }

    return (
    <div>
        <h3>Protected</h3>
        <h1>{store.isAuth ? `Пользователь авторизован ${store.user.email}` : 'АВТОРИЗУЙТЕСЬ'}</h1>
        <h1>{store.user.isActivated ? 'Аккаунт подтвержден по почте' : 'ПОДТВЕРДИТЕ АККАУНТ!!!!'}</h1>
        <button onClick={logout}>Выйти</button>
        <div>
            <button onClick={getUsers}>Получить пользователей</button>
        </div>
        {users.map(user =>
            <div key={user.email}>{user.email}</div>
        )}

    </div>);
}

export default ProtectedPage
// TimeTable.tsx

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { WorkTime } from '../../models/WorkTime';
import UserService from '../../services/UserService';
import DefaultWorkTimesTable from '../../components/DefaultTimeTable';

const TimeTable: React.FC = () => {
  const [selectedDate, handleDateChange] = useState<dayjs.Dayjs | null>(dayjs());
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [workTimes, setWorkTimes] = useState<WorkTime[]>([]);
  const [defaultWorkTimes, setDefaultWorkTimes] = useState<WorkTime[]>([]);

  useEffect(() => {
    // Fetch default work times from the server
    UserService.fetchDefaultWorkTimes().then(response => {
      const fetchedDefaultWorkTimes = response.data;
  
      // Ensure there are default work times for all days of the week
      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      const missingDays = daysOfWeek.filter(day => !fetchedDefaultWorkTimes.some(workTime => workTime.day === day));
  
      const defaultWorkTimesWithDefaults = [
        ...fetchedDefaultWorkTimes,
        ...missingDays.map(day => ({
          day,
          startTime: '',
          endTime: '',
          date: selectedDate ? selectedDate.toDate() : new Date(),
        })),
      ];
  
      setDefaultWorkTimes(defaultWorkTimesWithDefaults);
    });
  
    // Fetch user-specific work times
    UserService.fetchWorkTimes().then(response => setWorkTimes(response.data));
  }, []);

  // Function to get default values for each day of the week
  const getDefaultWorkTimeForDay = (day: string): WorkTime => {
    const defaultWorkTime = defaultWorkTimes.find((workTime) => workTime.day === day);

    if (defaultWorkTime) {
      // If default work time exists, return it
      return defaultWorkTime;
    } else {
      // If default work time doesn't exist, create a placeholder with the current date
      const currentDate = selectedDate ? selectedDate.toDate() : new Date();
      return { day, startTime: '', endTime: '', date: currentDate };
    }
  };

  const handleAddOrUpdateWorkTime = async () => {
    if (selectedDate && startTime && endTime) {
      const existingWorkTimeIndex = workTimes.findIndex((workTime) =>
        dayjs(workTime.date).isSame(selectedDate, 'day')
      );

      const workTimeToUpdate: WorkTime = {
        _id: existingWorkTimeIndex !== -1 ? workTimes[existingWorkTimeIndex]._id : '',
        date: selectedDate.toDate(),
        startTime,
        endTime,
      };

      try {
        // Add or update work time on the server
        if (existingWorkTimeIndex !== -1) {
          // Update existing work time
          const response = await UserService.updateWorkTime(workTimeToUpdate);
          console.log('Update response:', response.data);
        } else {
          // Add new work time
          const response = await UserService.addWorkTime(workTimeToUpdate);
          console.log('Add response:', response.data);
        }
        // Update the state based on the operation (add or update)
        const updatedWorkTimes = [...workTimes];
        if (existingWorkTimeIndex !== -1) {
          // Update existing work time
          updatedWorkTimes[existingWorkTimeIndex] = workTimeToUpdate;
        } else {
          // Add new work time
          updatedWorkTimes.push(workTimeToUpdate);
        }
        setWorkTimes(updatedWorkTimes);
      } catch (error) {
        // Handle errors
        console.error('Error adding/updating work time:', error);
      }
    }
  };

  const handleDeleteWorkTime = async (_id: string | undefined) => {
    if (!_id) {
      // Handle the case where the ID is undefined (no work time for the selected day)
      console.error('Invalid ID for deletion');
      return;
    }

    // Delete work time on the server
    await UserService.deleteWorkTime(_id);

    // Update the state after deletion
    setWorkTimes((prevWorkTimes) => prevWorkTimes.filter((workTime) => workTime._id !== _id));
  };

    // New functions for handling default work times
    const handleDefaultStartTimeChange = (value: string, defaultWorkTime: WorkTime) => {
      const updatedDefaultWorkTimes = defaultWorkTimes.map((wt) =>
        wt.day === defaultWorkTime.day ? { ...wt, startTime: value } : wt
      );
      setDefaultWorkTimes(updatedDefaultWorkTimes);
    };
  
    const handleDefaultEndTimeChange = (value: string, defaultWorkTime: WorkTime) => {
      const updatedDefaultWorkTimes = defaultWorkTimes.map((wt) =>
        wt.day === defaultWorkTime.day ? { ...wt, endTime: value } : wt
      );
      setDefaultWorkTimes(updatedDefaultWorkTimes);
    };
  
    const handleSetDefaultWorkTime = async (defaultWorkTime: WorkTime) => {
      try {
        // Update default work time on the server
        const response = await UserService.updateDefaultWorkTime(defaultWorkTime);
        console.log('Update default work time response:', response.data);
    
        // Check if the response is not null
        if (response.data !== null) {
          // Update the state after successful update
          setDefaultWorkTimes((prevDefaultWorkTimes) =>
            prevDefaultWorkTimes.map((workTime) =>
              workTime.day === defaultWorkTime.day ? defaultWorkTime : workTime
            )
          );
        } else {
          console.error('Error updating default work time: Response is null.');
        }
      } catch (error) {
        console.error('Error updating default work time:', error);
      }
    };
    
  

  const displayWeekWorkTimes = () => {
    if (selectedDate) {
      const weekDates = getWeekFromDate(selectedDate.toDate());

      return weekDates.map((day) => {
        const workTimeForDay = workTimes.find((workTime) =>
          dayjs(workTime.date).isSame(day, 'day')
        );

        // Use default values for the day if work time is not found
        const defaultWorkTime = getDefaultWorkTimeForDay(dayjs(day).format('dddd'));

        return (
          <TableRow key={day.getTime()}>
            <TableCell>{dayjs(day).format('ddd, YYYY-MM-DD')}</TableCell>
            <TableCell>{workTimeForDay?.startTime || defaultWorkTime.startTime}</TableCell>
            <TableCell>{workTimeForDay?.endTime || defaultWorkTime.endTime}</TableCell>
            <TableCell>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDeleteWorkTime(workTimeForDay?._id || '')}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    }
    return [];
  };

  const getWeekFromDate = (date: Date): Date[] => {
    const week = [];
    const startDate = dayjs(date).startOf('week').add(1, 'day'); // Start from Monday

    for (let i = 0; i < 14; i++) {
      const currentDate = startDate.add(i, 'day').toDate();
      week.push(currentDate);
    }

    return week;
  };

  return (
    <div style={{ marginTop: '20px', gap: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={(newValue) => handleDateChange(newValue)}
        />
      </LocalizationProvider>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          label="Start Time"
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label="End Time"
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <Button variant="contained" color="primary" onClick={handleAddOrUpdateWorkTime} style={{ margin: '10px 0' }}>
        Add/Update Work Time
      </Button>

      <Paper style={{ width: '100%', marginTop: '20px', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{displayWeekWorkTimes()}</TableBody>
        </Table>
      </Paper>

      {/* Display and update default work times */}
      <Typography variant="h6" style={{ marginTop: '20px' }}>Default Work Times</Typography>
      <DefaultWorkTimesTable
        defaultWorkTimes={defaultWorkTimes}
        onUpdateStartTime={handleDefaultStartTimeChange}
        onUpdateEndTime={handleDefaultEndTimeChange}
        onSetDefaultWorkTime={handleSetDefaultWorkTime}
      />
    </div>
  );
};

export default TimeTable;

import React from 'react';
import {Box, Card, Tooltip} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

function AddCategoryButton({openModal}) {

    const clickHandler = () => {
        openModal()
    }

    return (
            <Box onClick={clickHandler} sx={{margin: '10px', display: 'flex', flexDirection: 'column', cursor: 'pointer',}}>
                <Card sx={{
                    flexGrow: 1,
                    width: 150,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Tooltip title='Create new category'><AddIcon fontSize='small'/></Tooltip>
                </Card>
            </Box>
    );
}

export default AddCategoryButton;

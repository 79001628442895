import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AdminPageContent from "./AdminPageContent";

function AdminPage() {
    return (
            <Container disableGutters maxWidth="lg" component="main">
                <Box sx={{
                    height: '100vh',
                }}>
                    <AdminPageContent />
                </Box>
            </Container>
    );
}

export default AdminPage
import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, CircularProgress,
    IconButton,
    Paper, Tooltip,
    Typography
} from "@mui/material";
import UserService from "../../services/UserService";
import { IDish } from "../../models/IDish";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import StyledDialog from "../AdminPage/Dialog";
import Categories from "../../components/categories/Categorylist";
import { InfoOutlined } from "@mui/icons-material";
import AddDishButton from "./AddDishButton";
import LanguageSwitchButton from '../../components/translate/LanguageSwitchButton';
import { useTranslation } from 'react-i18next';

const emptyDishData = {
    name: '',
    nameTranslation:'',
    image: '',
    time: 0,
    price: 0,
    isBlocked: false,
    ingredients: [],
    ingredientsTranslation: [],
    isActiveElem: false,
    order: 0,
    extras: [],
}

function DishesList(addTranslations) {
    const { store } = useContext(Context)
    const [dishes, setDishes] = React.useState<IDish[]>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dishData, setDishData] = React.useState<IDish>(emptyDishData);
    const [isActiveElem, setActiveElem] = React.useState<IDish>(null)
    const [openTooltipIndex, setOpenTooltipIndex] = React.useState<IDish>(null);
    const { t, i18n } = useTranslation();
    const handleClickTooltip = (dish) => {
        if (dish !== openTooltipIndex) {
            setOpenTooltipIndex(dish)
        }
        if (dish === openTooltipIndex) {
            setOpenTooltipIndex(null)
        }
    }

    const handleOpenDialog = (item) => {
        setDishData(item)
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDishData(emptyDishData)
        getDishes()
    }

    async function getDishes() {
        try {
            const response = await UserService.fetchDishes();
            console.log(response.data)
            setDishes(response.data)
        } catch (e) {
            console.log(e);
        }
    }
    const filterCategories = d => {
        if (store.selectedCategory.name === "All") return true;
        return d?.category?.name === store.selectedCategory.name;
      };
      


    useEffect(() => {
        getDishes()
    }, [])

    const dragItem = useRef<IDish>()

    const dragOver = (e, item: IDish) => {
        dragItem.current = item
    }

    async function drop(e, item: IDish) {
        const dropItem = dragItem.current
        const startItem = item

        if (!startItem || !dropItem) return

        const copyListItems = dishes.map(dish => {
            if (dish._id === startItem._id) {
                return { ...dish, order: dropItem.order }
            }
            else if (dish._id === dropItem._id) {
                return { ...dish, order: startItem.order }
            }
            else return { ...dish }
        })

        dragItem.current = null
        setDishes(copyListItems)
        await UserService.updateDishes(copyListItems)

    }

    const handleClick = (dish) => {

        if (dish !== isActiveElem) {
            setActiveElem(dish)
        }
        if (dish === isActiveElem) {
            setActiveElem(null)
        }
    }

    return (
        <Container disableGutters maxWidth="xl" component="div">
            <LanguageSwitchButton />
            <StyledDialog
                open={openDialog}
                onClose={handleCloseDialog}
                dishData={dishData}
                setDishData={setDishData}
                addTranslations={addTranslations}
            />
            <Box sx={{
                display: 'flex',
            }}>
                <Box sx={{ flexGrow: 1, minWidth: '100%', mt: 3, height: '100%' }}>
                        <Categories  />
                    <Box>
                        <Paper elevation={3}>
                            <Box p={3} sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap',
                                maxHeight: '100%',
                                overflow: 'auto'
                            }}>
                                <AddDishButton openModal={() => handleOpenDialog(emptyDishData)} />
                                {dishes.length && dishes.filter(filterCategories).sort((a, b) => a.order - b.order).map((dish, order) => 
                                    (
                                        <Box
                                            draggable={true}
                                            key={order}
                                            onDragOver={(e) => dragOver(e, dish)}
                                            onDragEnd={(e) => drop(e, dish)}
                                            sx={{ margin: '10px', cursor: 'grab', }}>
                                            <Card sx={{
                                                width: 250,
                                                height: 320,
                                                position: 'relative',
                                                backgroundColor: dish.isBlocked ? 'red' : 'auto'
                                            }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        position: 'absolute',
                                                        justifyContent: 'space-between',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0
                                                    }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#D27919',
                                                            padding: '5px 15px',
                                                            color: '#fff',
                                                            display: isActiveElem === dish ? 'none' : ''
                                                        }}>{dish.price} {t('LKR')}
                                                    </div>
                                                    <div style={{
                                                        backgroundColor: '#D27919',
                                                        padding: '5px 15px',
                                                        color: '#fff',
                                                        display: isActiveElem === dish ? 'none' : ''
                                                    }}>{Math.ceil(dish.time / 60)} {t('min')}
                                                    </div>
                                                </div>
                                                <CardMedia
                                                    component="img"
                                                    alt="dish"
                                                    height="150"
                                                    image={dish.image}
                                                    onClick={() => { handleClick(dish) }}
                                                    sx={{ height: isActiveElem === dish ? '300px' : '150px' }}
                                                />
                                                <CardContent>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Typography variant="h5" component="div">
                                                            {i18n.language === 'uk' && dish.nameTranslation
                                                                ? `${dish.nameTranslation}`
                                                                : `${dish.name}`}
                                                        </Typography>
                                                        <Tooltip
                                                            open={openTooltipIndex === dish}
                                                            onClose={() => setOpenTooltipIndex(null)}
                                                            title={
                                                                i18n.language === 'uk'
                                                                    ? `Складові: ${dish.ingredientsTranslation.join(', ')}`
                                                                    : `Ingredients: ${dish.ingredients.join(', ')}`
                                                            }>
                                                            <IconButton onClick={() => handleClickTooltip(dish)}>
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        sx={{ backgroundColor: '#1976D2', position: 'absolute', bottom: '7px', right: '5px', width: '240px' }}
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => {
                                                            handleOpenDialog(dish)
                                                        }}>EDIT</Button>
                                                </CardActions>
                                            </Card>
                                        </Box>))
                                }
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default observer(DishesList)

import React from 'react';

import { useCSVDownloader } from 'react-papaparse';

export default function CSVDownload({data}) {
    const { CSVDownloader, Type } = useCSVDownloader();

    return (
        <CSVDownloader
            sx={{backgroundColor:'#1976D2'}}
            type={Type.Button}
            filename={'filename'}
            bom={true}
            config={{
                delimiter: ';',
            }}
            data={data}
        >
            Download
        </CSVDownloader>
    );
}
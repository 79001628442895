import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import {Typography} from "@mui/material";

const Link = ({children, to, variant, ...rest}) => (
    <RouterLink to={to}>
        <Typography variant={variant} {...rest}>
            {children}
        </Typography>
    </RouterLink>
);

export default Link;
// DefaultWorkTimesTable.tsx

import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Button, TextField } from '@mui/material';
import { WorkTime } from '../models/WorkTime';

interface DefaultWorkTimesTableProps {
  defaultWorkTimes: WorkTime[];
  onUpdateStartTime: (value: string, defaultWorkTime: WorkTime) => void;
  onUpdateEndTime: (value: string, defaultWorkTime: WorkTime) => void;
  onSetDefaultWorkTime: (defaultWorkTime: WorkTime) => void;
}

const DefaultWorkTimesTable: React.FC<DefaultWorkTimesTableProps> = ({
  defaultWorkTimes,
  onUpdateStartTime,
  onUpdateEndTime,
  onSetDefaultWorkTime,
}) => {
  return (
    <Table style={{ width: '100%', marginTop: '20px', overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell>Day</TableCell>
          <TableCell>Start Time</TableCell>
          <TableCell>End Time</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {defaultWorkTimes.map((defaultWorkTime) => (
          <TableRow key={defaultWorkTime.day}>
            <TableCell>{defaultWorkTime.day}</TableCell>
            <TableCell>
              <TextField
                value={defaultWorkTime.startTime}
                onChange={(e) => onUpdateStartTime(e.target.value, defaultWorkTime)}
              />
            </TableCell>
            <TableCell>
              <TextField
                value={defaultWorkTime.endTime}
                onChange={(e) => onUpdateEndTime(e.target.value, defaultWorkTime)}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onSetDefaultWorkTime(defaultWorkTime)}
              >
                Set as Default
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DefaultWorkTimesTable;

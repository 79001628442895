import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import UserService from "../../services/UserService";
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbarExport, GridToolbarContainer,} from "@mui/x-data-grid"
import {IOrderCSV, mutateData} from "./Orders";
import moment from 'moment'
import { MAIN_CURRENCY } from '../../constants/Currency';

interface IOrderTable extends IOrderCSV {
    id: number
    table: number;
    dishName: string;
    dishCount: number;
    dishPrice: number;
    dishesPrice: number;
    orderTime: string;
    dishStatus: string;
    dishPaid: string;
    clientName: string;
    comment: string;
    dishCategory: string;
}
export default function Bills() {
  const [orders, setOrders] = useState<IOrderTable[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<IOrderTable[]>([]);
  const [isMonthSubscribed, setIsMonthSubscribed] = useState(false);
  const [isWeekSubscribed, setIsWeekSubscribed] = useState(false);
  
  const handleSelectionModelChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  }
  
  function summarizePrice(orders, selectedRows) {
    let totalPrice = 0;
  
    selectedRows.forEach((rowIndex) => {
      const selectedRow = orders[rowIndex];
      totalPrice += selectedRow.dishPrice * selectedRow.dishCount;
    });
  
    if (isMonthSubscribed) {
      totalPrice -= 1500;
    }
  
    if (isWeekSubscribed) {
      totalPrice -= getDiscountForDrinks(orders, selectedRows);
    }
  
    return totalPrice;
  }
  
  function getDiscountForDrinks(orders, selectedRows) {
    const drinks = selectedRows.filter(
      (rowIndex) => orders[rowIndex].category === "drinks"
    );
  
    if (drinks.length === 0) {
      return 0;
    }
  
    const drinkWithHighestPrice = drinks.reduce((acc, curr) => {
      return orders[curr].dishPrice > orders[acc].dishPrice ? curr : acc;
    }, drinks[0]);
  
    return orders[drinkWithHighestPrice].dishPrice;
  }
  const totalPrice = summarizePrice(orders, selectedRows);

  
    useEffect(() => {
        getOrdersData().finally(() => setLoading(false))
    }, [])

    function CustomToolbar() {
    
      function handleMonthSubscription(event) {
        setIsMonthSubscribed(event.target.checked);
      }
    
      function handleWeekSubscription(event) {
        setIsWeekSubscribed(event.target.checked);
      }
    
      return (
        <Paper>
          <GridToolbarContainer sx={{justifyContent: 'flex-start'}}>
            <GridToolbarExport />
            <Typography sx={{margin: 'auto', fontWeight:'bold', fontSize:'1rem'}}>Total Price: {totalPrice}{MAIN_CURRENCY}</Typography>
            <FormControlLabel
              control={<Checkbox checked={isMonthSubscribed} onChange={handleMonthSubscription} />}
              label="Month Subs"
            />
            <FormControlLabel
              control={<Checkbox checked={isWeekSubscribed} onChange={handleWeekSubscription} />}
              label="Week Subs"
            />
          </GridToolbarContainer>
        </Paper>
      );
    }
    
      
      async function getOrdersData() {
        setLoading(true);
        const data = await UserService.getOrdersCSV();
        const mutatedData = mutateData(data.data);
        const slicedData = mutatedData.map((item, idx) => ({ ...item, id: idx }));
        setOrders(slicedData);
    }   

    const columns: GridColDef[] = [
        {field: 'orderId', headerName: 'orderId', width: 230, hide: true},
        {field: 'table', headerName: 'table', width: 60},
        {field: 'dishName', headerName: 'dishName', width: 160},
        {field: 'dishCount', headerName: 'dishCount', width: 80},
        {field: 'dishPrice', headerName: 'dishPrice', width: 100},
        {
            field: 'orderTime',
            headerName: 'orderTime',
            width: 200,
            renderCell: (params: GridRenderCellParams<Date>) => {
                moment().format('hh:mm, dd mm yyyy')
                return moment(params.row.orderTime).format('hh:mm, dddd (DD-MM-yyyy)')
            }
        },
        {field: 'dishStatus', headerName: 'dishStatus', width: 160},
        {field: 'dishPaid', headerName: 'dishPaid', width: 140},
        {
            field: 'clientName',
            headerName: 'Comment',
            width: 300,
            renderCell: (params: GridRenderCellParams) => {
              const { clientName, comment } = params.row;
              
              return (
                <div>
                  {clientName} - {comment}
                </div>
              );
            }
        }
    ];
    return (
        <Paper sx={{ width: '100%'}}>
            
            <DataGrid<IOrderTable>
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'orderTime', sort: 'desc' }],
                    },
                }}
                components={{Toolbar: CustomToolbar}}
                rows={orders || []}
                loading={loading}
                columns={columns}
                pageSize={100}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{newEditingApi: true}}
                onSelectionModelChange={handleSelectionModelChange}
                autoHeight
            />
            
        </Paper>
    );
}
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { IOrder } from '../../models/IOrder';
import UserService from '../../services/UserService';
import { Desktop, TabletAndBelow } from '../../layout/Responsive';
import { IDish } from '../../models/IDish';

interface DiscountProps {
  selectedOrder: IOrder | null;
  setSelectedOrder: (order: IOrder) => void;
  updateTotalPrice: (newTotalPrice: number) => void;
}

const Discount: React.FC<DiscountProps> = ({ selectedOrder, setSelectedOrder, updateTotalPrice }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] = useState<string>('');

  // Handle discount selection
  const handleDiscountChange = (event: SelectChangeEvent<string>) => {
    setSelectedDiscount(event.target.value);
  };

  // Add discount to the selected order
  const addDiscountToOrder = async () => {
    if (selectedOrder) {
      let discountPrice = 0;
      
      if (selectedDiscount === 'month') {
        discountPrice = Math.min(1500, selectedOrder.totalPrice);
      } else if (selectedDiscount === 'week') {
        const categoryIndex = selectedOrder.dishesCategory.findIndex(
          (category) => category.name === 'Drinks'
        );
  
        if (categoryIndex !== -1) {
          const drinksInCategory = selectedOrder.dishes.filter(
            (dish, index) => selectedOrder.dishesCategory[index].name === 'Drinks'
          );
  
          if (drinksInCategory.length > 0) {
            // Find the drink with the highest price
            const highestPriceDrink = drinksInCategory.reduce(
              (highest, dish) => (dish.price > highest.price ? dish : highest),
              drinksInCategory[0]
            );
  
            discountPrice = highestPriceDrink.price;
            console.log(`Highest Price Drink: ${highestPriceDrink.name}, Price: ${highestPriceDrink.price}`);
          } else {
            console.log('No drinks found in the "Drinks" category.');
          }
        } else {
          console.log('No "Drinks" category found in the order.');
        }
      }
  
      console.log(`Discount Price for ${selectedDiscount}: ${discountPrice}`);
      
      // Add the new discount to the existing discounts array
      const updatedDiscounts = [
        ...selectedOrder.discounts,
        {
          name: selectedDiscount,
          price: discountPrice,
        },
      ];
      
      const updatedOrder = {
        ...selectedOrder,
        discounts: updatedDiscounts,
      };
      updateTotalPrice(updatedOrder.totalPrice);
      try {
        await UserService.updateOrder(updatedOrder);
        console.log('Order updated successfully.');
        setSelectedOrder(updatedOrder);
        setIsOpen(false);
      } catch (error) {
        console.error('Error updating order:', error);
      }
    }
  };
  
  
  

  // Reset the discount in the selected order
  const resetDiscount = async () => {
    if (selectedOrder) {
      const updatedOrder = {
        ...selectedOrder,
        discounts: [],
      };
      updateTotalPrice(updatedOrder.totalPrice);
      try {
        await UserService.updateOrder(updatedOrder);
        console.log('Order updated successfully.');
        setSelectedOrder(updatedOrder);
        setIsOpen(false);
      } catch (error) {
        console.error('Error updating order:', error);
      }
    }
  };

  // Open the discount dialog
  const handleOpen = () => {
    setIsOpen(true);
  };

  // Close the discount dialog
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ position: 'relative', mt: 1, mb: 2 }}>
      {!isOpen ? (
        <Button
          sx={{ width: '100%' }}
          size="medium"
          variant="outlined"
          onClick={handleOpen}
          disabled={!selectedOrder}
        >
          Add Discount
        </Button>
      ) : (
        <>
          <Desktop>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                backgroundColor: 'white',
                padding: '10px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                minWidth: '200px',
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Add Discount
              </Typography>
              <FormControl sx={{ minWidth: '100%' }}>
                <InputLabel id="discount-type-label">Discount Type</InputLabel>
                <Select
                  labelId="discount-type-label"
                  id="discount-type"
                  value={selectedDiscount}
                  onChange={handleDiscountChange}
                  fullWidth
                >
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button variant="outlined" onClick={addDiscountToOrder} disabled={!selectedDiscount}>
                  Add Discount
                </Button>
                <Button variant="outlined" onClick={resetDiscount} sx={{ marginLeft: '10px' }}>
                  Reset Discount
                </Button>
                <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: '10px' }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Desktop>
          <TabletAndBelow>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: 'white',
                padding: '10px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                minWidth: '200px',
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Add Discount
              </Typography>
              <FormControl sx={{ minWidth: '100%' }}>
                <InputLabel id="discount-type-label">Discount Type</InputLabel>
                <Select
                  labelId="discount-type-label"
                  id="discount-type"
                  value={selectedDiscount}
                  onChange={handleDiscountChange}
                  fullWidth
                >
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button variant="outlined" onClick={addDiscountToOrder} disabled={!selectedDiscount}>
                  Add Discount
                </Button>
                <Button variant="outlined" onClick={resetDiscount} sx={{ marginLeft: '10px' }}>
                  Reset Discount
                </Button>
                <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: '10px' }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </TabletAndBelow>
        </>
      )}
    </Box>
  );
};

export default Discount;

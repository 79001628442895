import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../constants/PublicRoutes';
import { styled } from '@mui/material/styles';
import tablesImage from '../assets/main.png';
import { Desktop, TabletAndBelow } from '../layout/Responsive';

const StyledBox = styled(Box)`
  background-color: rgba(148, 187, 233, 1);
  padding: 4px;
  border-radius: 5px;

  &:hover {
    background-color: #1976d2;
    cursor: pointer;
  }
`;

function MainPage() {
  const tableCount = 12;
  const tableData = Array(tableCount).fill(0);
  const navigate = useNavigate();
  const [selectedTable, setSelectedTable] = React.useState('');

  const selectTable = (table) => {
    navigate(PublicRoutes.CLIENT_DIRECTORY_TABLE(table));
  };

  const handleChooseTable = () => {
    if (selectedTable) {
      navigate(PublicRoutes.CLIENT_DIRECTORY_TABLE(selectedTable));
    }
  };

  return (
    <Container disableGutters maxWidth="xl" component="main">
      <Box
        sx={{
          paddingBottom: 2,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Desktop>
          <Typography mt={2} mb={2} component="div" variant="h3">
            Please, select table:
          </Typography>
          <TextField
            label="Table Number"
            variant="outlined"
            type="number"
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
          />
            <Button  sx={{margin:'10px'}} variant="contained" onClick={handleChooseTable}>
              Go to Table
            </Button>
        </Desktop>
        <TabletAndBelow>
          <Typography mt={2} mb={2} component="div" variant="h4">
            Please, select table:
          </Typography>
          {/* Replace the Tables component with a TextField */}
          <TextField
            label="Table Number"
            variant="outlined"
            type="number"
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
          />
          <StyledBox>
            {/* Replace the onClick handler with handleChooseTable */}
            <Button variant="contained" onClick={handleChooseTable}>
              Go to Table
            </Button>
          </StyledBox>
        </TabletAndBelow>
      </Box>
    </Container>
  );
}

export default MainPage;

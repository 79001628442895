import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button, CircularProgress } from "@mui/material";
import { IDish } from "../../models/IDish";
import EditDish from "./EditDish";
import { useContext, useState } from "react";
import { Context } from "../../index";

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    dishData: IDish;
    setDishData: (dish: IDish) => void;
    addTranslations: () => void; // Add the prop for addTranslations function
}

function StyledDialog(props: SimpleDialogProps) {
    const { store } = useContext(Context);
    const { onClose, open, dishData, setDishData, addTranslations } = props; // Destructure addTranslations prop
    const [isLoading, setLoading] = useState<boolean>(false);

    const submitHandler = async () => {
        setLoading(true);
        await store.updateDish(dishData).finally(() => setLoading(false));
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={open}>
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '9999'
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <DialogTitle>Edit or create dish</DialogTitle>
            <EditDish dish={dishData} setDish={setDishData} addTranslations={addTranslations} /> 
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }} m={2}>
                <Button
                    fullWidth
                    variant='contained'
                    sx={{ marginRight: 2, backgroundColor: '#1976D2' }}
                    onClick={() => {
                        submitHandler();
                        addTranslations(); // Call addTranslations function
                    }}
                >
                    Save
                </Button>
                <Button fullWidth variant='contained' color='warning' onClick={onClose} sx={{ backgroundColor: '#1976D2' }}>
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
}

export default StyledDialog;

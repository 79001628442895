import $api from "../http";
import { AxiosResponse } from 'axios';
import { IUser } from "../models/IUser";
import { IDish } from "../models/IDish";
import { IOrder } from "../models/IOrder";
import { ICategory } from "../models/ICategory";
import { WorkTime } from "../models/WorkTime";

export default class UserService {

  // User API requests
  static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>('/users')
  }

  // Dish API requests
  static fetchDishes(): Promise<AxiosResponse<IDish[]>> {
    return $api.get<IDish[]>('/dishes')
  }
  static updateDish(dish: IDish): Promise<AxiosResponse> {
    return $api.post('/update-dish', { dish })
  }
  static updateDishes(dishes: any): Promise<AxiosResponse> {
    const mapDishes = dishes.map(dish => { return { _id: dish._id, order: dish.order } })
    return $api.post('/update-dishes', { dishes: mapDishes })
  }
  static fetchExtrasDishes(): Promise<AxiosResponse<IDish[]>> {
    return $api.get<IDish[]>('/extras-dishes');
  }
  static fetchDishesByIds(dishIds: string[]): Promise<AxiosResponse<IDish[]>> {
    const dishIdsParam = dishIds.join(',');

    return $api.get<IDish[]>(`/dishes/${dishIdsParam}`);
  }
  // Category API requests
  static fetchCategories(): Promise<AxiosResponse<ICategory[]>> {
    return $api.get<ICategory[]>('/categories')
  }
  static updateCategory(category: ICategory): Promise<AxiosResponse> {
    return $api.post('/category-update', { category })
  }

  static deleteCategory(categoryId: string): Promise<AxiosResponse> {
    return $api.delete(`/category-delete/${categoryId}`);
  }

  static updateCategoriesOrder(categories: ICategory[]): Promise<AxiosResponse> {
    return $api.post('/update-categories-order', { categories });
  }

  static updateCategories(categories: any): Promise<AxiosResponse> {
    const mapCategories = categories.map(category => { return { _id: category._id, name: category.name } })
    return $api.post('/update-categories', { categories: mapCategories })
  }
  // Order API requests

  static fetchOrders(): Promise<AxiosResponse<IOrder[]>> {
    return $api.get<IOrder[]>('/orders')
  }
  static getOrdersCSV(): Promise<AxiosResponse<IOrder[]>> {
    return $api.get<IOrder[]>('/orders-csv')
  }
  static createOrder(order: IOrder): Promise<AxiosResponse> {
    return $api.post('/create-order', { order })
  }
  static completeOrder(order: IOrder): Promise<AxiosResponse> {
    return $api.post('/complete-order', { order })
  }
  static updateOrder(order: IOrder): Promise<AxiosResponse> {
    return $api.post('/update-order', { order })
  }
  static getTableOrders(tableNumber: number): Promise<AxiosResponse> {
    return $api.get('/table-orders', { params: { tableNumber } })
  }
  static getOrdersTree(date: string): Promise<AxiosResponse<IOrder[]>> {
    return $api.get<IOrder[]>('/orders-tree', { params: { date } });
  }
  static updateDishTimer(orderId: string, dishIndex: string, timerValue: number) {
    return $api.post('/update-dish-timer', { orderId, dishIndex, timerValue });
  }
  // Image upload API request
  static uploadImage(formData: FormData): Promise<AxiosResponse> {
    return $api.post('/upload-image', formData)
  }

  static fetchTranslation(language: string): Promise<AxiosResponse<any>> {
    return $api.get(`/translate/${language}`);
  }

  static addTranslation(language: string, translation: any): Promise<AxiosResponse> {
    return $api.post(`/translate/${language}`, { translation });
  }

  // WorkTime API requests
  static fetchWorkTimes(): Promise<AxiosResponse<WorkTime[]>> {
    return $api.get<WorkTime[]>('/work-times');
  }

  static addWorkTime(workTime: WorkTime): Promise<AxiosResponse> {
    return $api.post('/work-times/add', workTime);
  }

  static updateWorkTime(workTime: WorkTime): Promise<AxiosResponse> {
    const { _id, day, date, startTime, endTime } = workTime;
    return $api.put('/work-times/update', { workTimeId: _id, day, date, startTime, endTime });
  }

  static deleteWorkTime(workTimeId: string): Promise<AxiosResponse> {
    return $api.delete(`/work-times/${workTimeId}`);
  }

  // Default WorkTime API requests
  static fetchDefaultWorkTimes(): Promise<AxiosResponse<WorkTime[]>> {
    return $api.get<WorkTime[]>('/default-work-times');
  }

  static updateDefaultWorkTime(defaultWorkTime: WorkTime): Promise<AxiosResponse> {
    const { _id, day, startTime, endTime } = defaultWorkTime;
    return $api.post('/default-work-times/update', { defaultWorkTimeId: _id, day, startTime, endTime });
  }

  static deleteDefaultWorkTime(defaultWorkTimeId: string): Promise<AxiosResponse> {
    return $api.delete(`/default-work-times/${defaultWorkTimeId}`);
  }


}



import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ICategory } from '../../models/ICategory';


export default function EditCategory({category, setCategory}) {

    const handleChange =
        (prop: keyof ICategory) => (event: React.ChangeEvent<HTMLInputElement>) => {
                setCategory({...category, [prop]: event.target.value});
        };
    return (
        <Box sx={{m: 2}}>
            <TextField
                fullWidth
                value={category.name}
                onChange={handleChange('name')}
                label="Title"
                id="name"
                sx={{mt: 1, mb: 1}}
            />
        </Box>
    );
}

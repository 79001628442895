import React from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

function Volume({isOn, setOn}) {
    const turnOn = () => {
        setOn(true)
    }
    const turnOff = () => {
        setOn(false)
    }

    return (
        <>
            {isOn ? <VolumeUpIcon onClick={turnOff} fontSize='large' sx={{cursor: 'pointer'}}/> :
                <VolumeOffIcon onClick={turnOn} fontSize='large' sx={{cursor: 'pointer'}}/>}
        </>
    );
}

export default Volume;
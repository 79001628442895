import React from 'react';
import {Box, Card, Tooltip} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

function AddDishButton({openModal}) {

    const clickHandler = () => {
        openModal()
    }

    return (
        <Box onClick={clickHandler} sx={{margin: '10px', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
            <Card sx={{
                flexGrow: 1,
                width: 250,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Tooltip title='Create new dish'><AddIcon fontSize='large'/></Tooltip>
            </Card>
        </Box>
    );
}

export default AddDishButton;
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {IOrder} from "../../models/IOrder";
import {Alert, Badge} from "@mui/material";
import TableBarIcon from "@mui/icons-material/TableBar";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {getRandomInt, shuffle} from "../../helpers/helpers";
import {useEffect, useState} from "react";

const StyledBox = styled(Box)`
  background-color: rgba(148, 187, 233, 1);
  padding: 4px;
  border-radius: 5px;

  &:hover {
    background-color: #1976D2;
    cursor: pointer;
  }
`

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    order: IOrder;
    orderDone: (order: IOrder) => void;
}

const initAlert = {
    show: false, color: null, message: null
}

function StyledDialog(props: SimpleDialogProps) {
    const {onClose, open, order, orderDone,} = props;
    const [tableData, setTableData] = useState(generateTables(order.tableNumber))
    const [alert, setAlert] = useState(initAlert)


    const submitHandler = async () => {
        orderDone(order)
        onClose()
    }

    useEffect(()=>{
        setTableData(generateTables(order.tableNumber))
    }, [order])

    function generateTables(origin) {
        let one = null
        let two = null
        do {
            one = getRandomInt(1, 10)
            two = getRandomInt(1, 10)
        }
        while (one === two || one === origin || two === origin)
        return shuffle([origin, two, one])
    }

    useEffect(()=>{return ()=>setAlert(initAlert)},[])

    const checkTable = async (tableNumber) => {
        if (tableNumber === order.tableNumber) {
            setAlert({show: true, color: 'success', message: 'Great! Order completed!'})
            setTimeout(submitHandler, 1000)
        } else{
            setAlert({show: true, color: 'warning', message: 'Please choose appropriate table'})
            setTimeout(onClose, 1000)
        }

    }

    const closeAlert = () => {
        setAlert(initAlert)
    }

    return (
        <Dialog onClose={onClose} open={open}>
            {alert.show && <Alert color={alert.color} onClose={closeAlert}>{alert.message}</Alert>}
            <DialogTitle>Select table number</DialogTitle>
            <Box sx={{display: 'flex', justifyContent: 'space-around', mb: 2, minWidth: '350px'}}>
                {
                    tableData.map((num, index) => (
                        <StyledBox key={index}>
                            <Badge onClick={() => {
                                checkTable(num)
                            }} color="secondary" badgeContent={num} showZero
                            >
                                <TableBarIcon sx={{color: 'white'}}/>
                            </Badge>
                        </StyledBox>))
                }
            </Box>
        </Dialog>
    );
}

export default StyledDialog
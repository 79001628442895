// MyTimer.js
import { useEffect, useState } from 'react';
import { store } from '..';

const MyTimer = ({ orderId, dishId, createdAt, dishTime, updateDishEndTimes, isRunning }) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        const startTime = new Date(createdAt).getTime();
        const currentTime = new Date().getTime();
        const elapsedTime = Math.floor((currentTime - startTime) / 1000);
        setTimeLeft(dishTime - elapsedTime);

        // Load the saved timer value from the store
        const storedTimerValue = store.getTimerValue(orderId, dishId);
        if (storedTimerValue !== null) {
            setTimer(storedTimerValue);
        }

        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime > 0) {
                        return prevTime - 1;
                    } else if (prevTime === 0) {
                        clearInterval(interval);
                    }
                    return prevTime - 1;
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }

        // Save the current timer value to the store whenever it changes
        store.setTimerValue(orderId, dishId, timer);

        return () => clearInterval(interval);
    }, [orderId, dishId, createdAt, dishTime, isRunning, store, timer]);

    useEffect(() => {
        const formattedTime = formatTime(timeLeft);
        setTimer(formattedTime);

        if (isRunning && timeLeft < 0) {
            // Set the text color directly to red
            updateDishEndTimes(formattedTime);
        } else {
            updateDishEndTimes(formattedTime);
        }
    }, [timeLeft, isRunning, updateDishEndTimes]);

    const formatTime = (time) => {
        const absTime = Math.abs(time);
        const prefix = time < 0 ? '-' : '';
        const minutes = Math.floor(absTime / 60);
        const seconds = absTime % 60;
        return `${prefix}${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    return (
        <div>
            <h3 style={{ color: timeLeft < 0 ? 'red' : 'black' }}>{timer}</h3>
        </div>
    );
};

export default MyTimer;

import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper
} from "@mui/material";
import {useParams} from "react-router-dom";
import UserService from "../services/UserService";
import Typography from "@mui/material/Typography";
import {MAIN_CURRENCY} from "../constants/Currency";
import {Context} from "../index";
import { t } from 'i18next';

function OrderPage({refresh, setRefresh, isClient}) {
    const {store} = useContext(Context)
    const {tableNumber} = useParams()
    const [orders, setOrders] = useState([])

    useEffect(() => {
        if(!isClient) {
            const interval = setInterval(getOrders, 10000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [])

    useEffect(() => {
        getOrders()
    }, [])

    useEffect(() => {
        if(refresh) getOrders()
        setRefresh(false)
    }, [refresh])

    async function getOrders() {
        console.log({refresh})
        if(tableNumber){
            const response = await UserService.getTableOrders(Number(tableNumber))
            setOrders(response.data)
        }
        else if(!tableNumber && store.isAuth){
            const data = await UserService.getOrdersCSV().then(({data})=>data.filter(o=>o.status === "created"))
            setOrders(data)
        }
    }

    return (
        <>
            {orders.length ? orders.map((order) => (
                    <Paper key={order._id} elevation={3} sx={{padding: 2, marginTop: 2}}>
                        <Typography variant="h6">{t('Table number is:')} {order.tableNumber}</Typography>
                        <List>
                            {order.dishes.length && order.dishes.map((dish, dishIdx) => (
                                <ListItem key={dish._id} 
                                sx={order.dishesCancel[dishIdx] ? {
                                    backgroundColor: 'rgba(125, 46, 50 .1)',
                                    borderRadius: '4px'
                                }: 
                                    order.dishesDone[dishIdx] ? {
                                    backgroundColor: 'rgba(46, 125, 50, .1)',
                                    borderRadius: '4px'
                                } : {}}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={dish.image}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${dish.name} x ${order.dishesCount[dishIdx]}`}
                                        secondary={`${Math.round(dish.price * order.dishesCount[dishIdx])} ${MAIN_CURRENCY}`}
                                    />
                                </ListItem>))}
                        </List>
                        <Typography>Total: {order.totalPrice} {MAIN_CURRENCY}</Typography>
                        <Divider/>
                    </Paper>)) :
                <Paper elevation={3} sx={{padding: 2, marginTop: 2}}>
                    <Typography variant="body1">{t('No Orders')}</Typography>
                </Paper>}
        </>
    );
}

export default OrderPage;
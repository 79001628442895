import {IDish} from "./IDish";
import {IClient} from "./IClient";
import {IUser} from "./IUser";
import { ICategory } from "./ICategory";

export enum OrderStatus {
    created = 'created',
    cooking = 'cooking',
    ready = 'ready',
}

export interface IDiscount {
    id?: string;
    name: string;
    price: number;
}

export interface IOrder {
    _id?: string;
    dishes: IDish[];
    dishesCount: number[];
    dishesDone: boolean[];
    dishesCancel: boolean[];
    dishEndTimes: string[];
    createdAt?: string;
    client?: IClient;
    totalPrice: number;
    discounts: IDiscount[];
    comment?: string;
    manager?: string;
    cook?: IUser;
    paid?: string;
    tableNumber: number;
    status?: OrderStatus;
    clientName?: string;
    dishesCategory?: ICategory[];
}

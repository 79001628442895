import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import UserService from '../../services/UserService';
import { Box } from '@mui/material';
import { IDish } from '../../models/IDish';

interface ExtrasDialogProps {
  open: boolean;
  onClose: () => void;
  dish: IDish;
  onExtrasChange: (updatedExtras: string[]) => void; // Callback function for updating extras in EditDish
}

const ExtrasDialog: React.FC<ExtrasDialogProps> = ({ open, onClose, dish, onExtrasChange }) => {
  const [extrasDishes, setExtrasDishes] = useState<IDish[]>([]);
  const [addedExtrasLocal, setAddedExtrasLocal] = useState<IDish[]>([]);

  useEffect(() => {
    const fetchExtrasDishes = async () => {
      try {
        const response = await UserService.fetchExtrasDishes();
        setExtrasDishes(response.data);
      } catch (error) {
        console.error('Error fetching extras dishes:', error);
      }
    };

    fetchExtrasDishes();
  }, []);

  useEffect(() => {
    const fetchDishExtras = async () => {
      if (dish.extras && dish.extras.length > 0) {
        const fullExtras = await fetchDishesByIds(dish.extras);
        setAddedExtrasLocal(fullExtras);
      }
    };

    if (open) {
      fetchDishExtras();
    }
  }, [open, dish.extras]);

  const fetchDishesByIds = async (dishIds: string[]) => {
    try {
      const response = await UserService.fetchDishesByIds(dishIds);
      return response.data;
    } catch (error) {
      console.error('Error fetching dish objects by IDs:', error);
      return [];
    }
  };

  const handleAddExtra = (extra: IDish) => async () => {
    // Safely add extra only if not already present
    if (!addedExtrasLocal.find((addedExtra) => addedExtra._id === extra._id)) {
      // Ensure that extra._id is a non-empty string before making the request
      if (extra._id && typeof extra._id === 'string') {
        const fullExtra = await fetchDishesByIds([extra._id]);
        setAddedExtrasLocal([...addedExtrasLocal, ...fullExtra]);
      } else {
        console.error('Invalid extra._id:', extra._id);
      }
    }
  };

  const handleRemoveExtra = (extraId: string) => () => {
    // Create a copy to avoid mutating the original array
    const updatedExtras = addedExtrasLocal.filter((addedExtra) => addedExtra._id !== extraId);
    setAddedExtrasLocal(updatedExtras);
  };

  const handleSave = async () => {
    try {
      console.log('Saving dish with extras:', addedExtrasLocal);
      const updatedDish = {
        ...dish,
        extras: addedExtrasLocal.map((extra) => extra._id),
      };
      console.log('before', updatedDish);

      // Update the dish first
      await UserService.updateDish(updatedDish);
      console.log('after', updatedDish);

      // Pass the updated extras to the parent component
      onExtrasChange(updatedDish.extras);

      onClose();
    } catch (error) {
      console.error('Error updating dish with extras:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage Extras</DialogTitle>
      <DialogContent sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ flex: 1, borderRight: '1px solid #ccc', paddingRight: 2 }}>
          <strong>Added Extras:</strong>
          {addedExtrasLocal.map((extra) => (
            <Chip
              key={extra._id}
              label={extra.name}
              sx={{ m: 1, backgroundColor: '#4CAF50', color: 'white' }}
              onDelete={handleRemoveExtra(extra._id)}
            />
          ))}
        </Box>
        <Box sx={{ flex: 1, paddingLeft: 2 }}>
          <strong>Available Extras:</strong>
          {extrasDishes.map((extra) => (
            <Chip
              key={extra._id}
              label={extra.name}
              onClick={handleAddExtra(extra)}
              sx={{ m: 1, cursor: 'pointer' }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} startIcon={<SaveIcon />} color="primary">
          Save
        </Button>
        <Button onClick={onClose} endIcon={<CloseIcon />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtrasDialog;

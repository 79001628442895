import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    Avatar, Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper
} from "@mui/material";
import UserService from "../../services/UserService";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import StyledDialog from "./Dialog";
import Volume from "./Volume";
import MyTimer from '../../components/MyTimer';
import { green } from '@mui/material/colors';
import { t } from 'i18next';
import { useAudio } from '../../helpers/hooks';
const bellSound = require("../../assets/bell.mp3");

function KitchenPage() {
    const [orders, setOrders] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isSound, setSound] = useState(false);
    const [isRunning, setIsRunning] = useState(true);
    const [playing, makeSound] = useAudio(bellSound);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [formattedTime, setFormattedTime] = useState(null);

    const handleOpenDialog = useCallback(() => {
        setOpenDialog(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setOpenDialog(false);
    }, []);

    const memoizedItemDoneHandler = useCallback((item, order, formattedTime) => async () => {
        try {
            const tempOrders = [...orders];
            const orderIndex = tempOrders.findIndex((o) => o._id === order._id);
            const tempOrder = { ...order };

            const dishIndex = tempOrder.dishes.findIndex((d) => d._id === item._id);

            const statusDone = !tempOrder.dishesDone[dishIndex];

            tempOrder.dishesDone[dishIndex] = statusDone;

            // Update dishEndTimes when marking as done
            if (statusDone) {
                tempOrder.dishEndTimes[dishIndex] = formattedTime;
            } else {
                // If unmarked as done, set dishEndTimes back to null
                tempOrder.dishEndTimes[dishIndex] = null;
            }

            tempOrders[orderIndex] = tempOrder;
            setOrders(tempOrders);

            await UserService.updateOrder(tempOrder);
        } catch (e) {
            console.log(e);
        }
    }, [orders]);

    const memoizedItemCancelHandler = useCallback((item, order, formattedTime) => async () => {
        try {
            const tempOrders = [...orders];
            const orderIndex = tempOrders.findIndex((o) => o._id === order._id);
            const tempOrder = { ...order };

            const dishIndex = tempOrder.dishes.findIndex((d) => d._id === item._id);

            const statusCancel = !tempOrder.dishesCancel[dishIndex];

            tempOrder.dishesCancel[dishIndex] = statusCancel;

            // Update dishEndTimes when marking as canceled
            if (statusCancel) {
                tempOrder.dishEndTimes[dishIndex] = formattedTime;
            } else {
                // If unmarked as canceled, set dishEndTimes back to null
                tempOrder.dishEndTimes[dishIndex] = null;
            }

            let totalPrice = tempOrder.totalPrice;
            if (statusCancel) {
                totalPrice -= item.price;
                tempOrder.totalPrice = totalPrice;
            } else {
                totalPrice += item.price;
                tempOrder.totalPrice = totalPrice;
            }

            tempOrders[orderIndex] = tempOrder;
            setOrders(tempOrders);

            await UserService.updateOrder(tempOrder);
        } catch (e) {
            console.log(e);
        }
    }, [orders]);

    const memoizedGetOrders = useCallback(async () => {
        try {
            const response = await UserService.fetchOrders();
            setOrders(prevState => {
                if (!response?.data?.length)
                    return response.data
                const oldOrders = prevState.map(d => d._id)
                const newOrders = response.data.map(d => d._id)
                const mergedOrders = Array.from(new Set([...oldOrders, ...newOrders]))
                if (mergedOrders.length > oldOrders.length && !isSound && !playing)
                    makeSound()
                return response.data;
            });
        } catch (e) {
            console.log(e);
        }
    }, [isSound, playing, makeSound]);

    useEffect(() => {
        const interval = setInterval(memoizedGetOrders, 10000);
        return () => clearInterval(interval);
    }, [memoizedGetOrders]);

    useEffect(() => {
        memoizedGetOrders();
    }, [memoizedGetOrders]);

    const orderDone = useCallback(async (order) => {
        const tempOrders = [...orders];
        const orderIndex = tempOrders.findIndex(o => o._id === order._id);

        tempOrders.splice(orderIndex, 1);
        setOrders(tempOrders);
        await UserService.completeOrder(order);
    }, [orders]);

    const openModal = useCallback(async (order) => {
        if (order) {
            setSelectedOrder(order);
            handleOpenDialog();
        }
    }, [handleOpenDialog]);

    const disabledButton = useCallback((order) => {
        const { dishesDone, dishesCancel } = order;
        return dishesDone.some((val, i) => val === false && dishesCancel[i] === false);
    }, []);

    return (
        <Container disableGutters maxWidth="xl" component="main">
            {selectedOrder && (
                <StyledDialog open={openDialog} onClose={handleCloseDialog} order={selectedOrder} orderDone={orderDone} />
            )}
            <Box sx={{
                height: '2000px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto'
            }}>
                <Box sx={{ marginTop: '20px', marginLeft: '20px' }}>
                    <Volume isOn={isSound} setOn={setSound} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '20px',
                    marginLeft: '20px',
                }}>
                    {orders.length ? orders.map((order) => (
                        <Paper key={order._id} elevation={3} sx={{ padding: 2, marginRight: 2, maxWidth: '250px' }}>
                            <Typography variant="h6">{t('Table number is:')} {order.tableNumber}</Typography>
                            <List>
                                {order.clientName
                                    ?
                                    <div style={{ wordWrap: "break-word", width: '200px' }}>
                                        <span>ClientName: {order.clientName}</span>
                                    </div>
                                    : ''
                                }
                                {order.comment
                                    ?
                                    <div style={{ wordWrap: "break-word", width: '200px' }}>
                                        <span>Comment: {order.comment}</span>
                                    </div>
                                    : ''
                                }
                                {order.dishes.length && order.dishes.map((dish, dishIdx) => (
                                    <ListItem key={dish._id}
                                        sx={order.dishesCancel[dishIdx] ? {
                                            backgroundColor: 'rgba(125, 46, 50, .1)',
                                            borderRadius: '4px'
                                        } :
                                            order.dishesDone[dishIdx] ? {
                                                backgroundColor: 'rgba(46, 125, 50, .1)',
                                                borderRadius: '4px'
                                            } : {}}
                                    >
                                        <ListItemAvatar sx={{ marginBottom: 2, padding: 2, maxWidth: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Avatar sx={{ height: '70px', width: '70px' }} src={dish.image} />
                                                <MyTimer
                                                    key={dish._id}

                                                    createdAt={order.createdAt}
                                                    dishTime={dish.time}
                                                    isRunning={!order.dishesDone[dishIdx] && !order.dishesCancel[dishIdx] && isRunning}
                                                    updateDishEndTimes={(formattedTime) => {
                                                        if (isRunning) {
                                                            setFormattedTime(formattedTime);
                                                        }
                                                    }}
                                                    orderId={order._id}
                                                    dishId={dish._id} />

                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                <ListItemText
                                                    sx={{ overflow: 'hidden' }}
                                                    primary={dish.name}
                                                    secondary={`x ${order.dishesCount[dishIdx]}`}
                                                    secondaryTypographyProps={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                                                />
                                                {!order.dishesDone[dishIdx] &&
                                                    <CheckCircleOutlineOutlinedIcon sx={{ cursor: 'pointer', fontSize: '2.5rem' }} color="primary"
                                                        onClick={memoizedItemDoneHandler(dish, order, formattedTime)} />}
                                                {order.dishesDone[dishIdx] &&
                                                    <DoNotDisturbAltIcon sx={{ cursor: 'pointer', fontSize: '2.5rem' }} color="primary"
                                                        onClick={memoizedItemDoneHandler(dish, order, formattedTime)} />}
                                                {!order.dishesCancel[dishIdx] &&
                                                    <CancelIcon sx={{ cursor: 'pointer', fontSize: '2.5rem' }}
                                                        color="primary"
                                                        onClick={memoizedItemCancelHandler(dish, order, formattedTime)} />}
                                                {order.dishesCancel[dishIdx] &&
                                                    <DoNotDisturbAltIcon sx={{ cursor: 'pointer', fontSize: '2.5rem' }}
                                                        color="primary"
                                                        onClick={memoizedItemCancelHandler(dish, order, formattedTime)} />}
                                            </Box>
                                        </ListItemAvatar>
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <Button
                                sx={{ backgroundColor: '#1976D2' }}
                                disabled={disabledButton(order)}
                                variant="contained" fullWidth
                                onClick={() => openModal(order)}
                            >
                                DONE
                            </Button>
                        </Paper>
                    )) : <Typography variant="body1">Nothing to do</Typography>}
                </Box>
            </Box>
        </Container>
    );
}

export default KitchenPage;

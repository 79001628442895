import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface TreeOrdersFilterProps {
  onFilterChange: (filter: IFilter) => void;
}

interface IFilter {
  dishPaidStatus: string;
  sortByTableNumber: 'asc' | 'desc' | '';
  sortByTotalPrice: 'asc' | 'desc' | '';
  tableNumber: number | null;
}

const TreeOrdersFilter: React.FC<TreeOrdersFilterProps> = ({ onFilterChange }) => {
  const [filter, setFilter] = useState<IFilter>({
    dishPaidStatus: ' ',
    sortByTableNumber: '',
    sortByTotalPrice: '',
    tableNumber: null,
  });

  const handleFilterChange = (event: SelectChangeEvent<'asc' | 'desc'>) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));

    onFilterChange({
      ...filter,
      [name]: value,
    });
  };

  const handleTableNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsedValue = value !== '' ? parseInt(value) : null;
    setFilter((prevFilter) => ({
      ...prevFilter,
      tableNumber: parsedValue,
    }));

    onFilterChange({
      ...filter,
      tableNumber: parsedValue,
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        marginBottom: '20px',
        display: 'flex',
        rowGap: '10px',
        flexDirection: 'column',
        alignItems: isMobile ? 'center' : 'baseline',
        justifyContent: isMobile ? 'center' : 'flex-start',
        width:'100%'
      }}
    >
      <FormControl sx={{ width: '100%', marginBottom: isMobile ? '10px' : 0, marginRight: isMobile ? 0 : '10px' }}>
        <InputLabel htmlFor="dishPaidStatus">Paid Status</InputLabel>
        <Select
          value={filter.dishPaidStatus}
          name="dishPaidStatus"
          onChange={handleFilterChange}
          label="Paid Status"
          variant={isMobile ? 'standard' : 'outlined'}
          size={isMobile ? 'small' : 'medium'}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="Paid">Paid</MenuItem>
          <MenuItem value="Sponsored">Sponsored</MenuItem>
          <MenuItem value=" ">UnPaid</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ width: '100%', marginBottom: isMobile ? '10px' : 0, marginRight: isMobile ? 0 : '10px' }}>
        <InputLabel htmlFor="sortByTableNumber">Sort by Table</InputLabel>
        <Select
          value={filter.sortByTableNumber}
          name="sortByTableNumber"
          onChange={handleFilterChange}
          label="Sort by Table"
          variant={isMobile ? 'standard' : 'outlined'}
          size={isMobile ? 'small' : 'medium'}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="asc">Ascending</MenuItem>
          <MenuItem value="desc">Descending</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ width: '100%', marginBottom: isMobile ? '10px' : 0, marginRight: isMobile ? 0 : '10px' }}>
        <InputLabel htmlFor="sortByTotalPrice">Sort by Price</InputLabel>
        <Select
          value={filter.sortByTotalPrice}
          name="sortByTotalPrice"
          onChange={handleFilterChange}
          label="Sort by Price"
          variant={isMobile ? 'standard' : 'outlined'}
          size={isMobile ? 'small' : 'medium'}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="asc">Ascending</MenuItem>
          <MenuItem value="desc">Descending</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{width: '100%', }}>
        <TextField
          type="number"
          label="Table Number"
          value={filter.tableNumber !== null ? filter.tableNumber : ''}
          onChange={handleTableNumberChange}
          variant="outlined"
          size={isMobile ? 'small' : 'medium'}
        />
      </FormControl>
    </Box>
  );
};

export default TreeOrdersFilter;

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Button,
  Divider,
  Avatar,
  ButtonGroup,
  Menu,
} from '@mui/material';
import UserService from '../../services/UserService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { MAIN_CURRENCY } from '../../constants/Currency';
import { IDiscount, IOrder } from '../../models/IOrder';
import TreeOrdersFilter from './TreeOrdersFilter';
import Coworking from './Coworking';
import Discount from './Discount';
import { Desktop, TabletAndBelow } from '../../layout/Responsive';

function TreeOrders() {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [lastClickedOrder, setLastClickedOrder] = useState<IOrder | null>(null);
  const anchorRef = useRef(null);
  const [selectedOrders, setSelectedOrders] = useState<IOrder[]>([]);
  const [totalSelectedOrdersPrice, setTotalSelectedOrdersPrice] = useState<number>(0);
  const [filter, setFilter] = useState({
    dishPaidStatus: ' ',
    sortByTableNumber: '',
    sortByTotalPrice: '',
    tableNumber: null,
  });

  async function getOrders(date: string) {
    try {
      if (date) {
        const response = await UserService.getOrdersTree(date);
        setOrders(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }
  const updateTotalPrice = (newTotalPrice: number) => {
    setTotalSelectedOrdersPrice(newTotalPrice);
  };

  useEffect(() => {
    const interval = setInterval(() => getOrders(selectedDate?.format('YYYY-MM-DD[T00:00:00.000Z]') || ''), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedDate]);

  useEffect(() => {
    getOrders(selectedDate?.format('YYYY-MM-DD[T00:00:00.000Z]') || '');
  }, [selectedDate]);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handlePaid = async (order: IOrder, paid: string) => {
    try {
      // Update the order status
      const updatedOrder = { ...order, paid };
      await UserService.updateOrder(updatedOrder);
      console.log('Order updated successfully.');

      // Refresh the orders list
      getOrders(selectedDate?.format('YYYY-MM-DD[T00:00:00.000Z]') || '');
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const applyFilter = (order: IOrder) => {
    if (order.dishesCancel.every((isCanceled) => isCanceled)) {
      return false; // If all dishes are canceled, exclude the order
    }
    if (filter.tableNumber !== null && filter.tableNumber !== order.tableNumber) {
      return false;
    }
    if (filter.dishPaidStatus === ' ') {
      return order.paid !== 'Paid' && order.paid !== 'Sponsored';
    } else if (filter.dishPaidStatus && order.paid !== filter.dishPaidStatus) {
      return false;
    }
    return true;
  };

  const applySorting = (orderA, orderB) => {
    if (filter.sortByTableNumber === 'asc') {
      return orderA.tableNumber - orderB.tableNumber;
    }
    if (filter.sortByTableNumber === 'desc') {
      return orderB.tableNumber - orderA.tableNumber;
    }
    if (filter.sortByTotalPrice === 'asc') {
      return orderA.totalPrice - orderB.totalPrice;
    }
    if (filter.sortByTotalPrice === 'desc') {
      return orderB.totalPrice - orderA.totalPrice;
    }
    return 0;
  };

  const filteredOrders = orders.filter(applyFilter).sort(applySorting);

  const handleOrderClick = (event, order) => {
    event.stopPropagation();

    setLastClickedOrder(order);

    const orderIndex = selectedOrders.findIndex((selectedOrder) => selectedOrder._id === order._id);

    if (orderIndex !== -1) {
      // If the order already exists, remove it
      const updatedOrders = [...selectedOrders];
      updatedOrders.splice(orderIndex, 1);
      setSelectedOrders(updatedOrders);
    } else {
      // If the order doesn't exist, add it to the selected orders
      setSelectedOrders([...selectedOrders, order]);
    }
  };

  useEffect(() => {
    const calculateTotalPrice = (order: IOrder): number => {
      if (order.discounts && order.discounts.length > 0) {
        const discountsPrice = order.discounts.reduce((total, discount) => total + discount.price, 0);
        const discountedPrice = order.totalPrice - discountsPrice;

        // Ensure that the discounted price is not below 0
        return discountedPrice < 0 ? 0 : discountedPrice;
      }
      return order.totalPrice;
    };

    const total = selectedOrders.reduce((sum, order) => sum + calculateTotalPrice(order), 0);
    updateTotalPrice(total); // Update the total prices when selectedOrders change
  }, [selectedOrders]);

  const getUniqueDiscountNames = (discounts: IDiscount[]) => {
    const uniqueNames: string[] = Array.from(new Set(discounts.map((discount) => discount.name)));
    return uniqueNames.filter((name) => name !== ''); // Filter out empty names
  };
  
  return (
    <Container disableGutters maxWidth="xl" component="main">
      <Box sx={{ position: 'relative' }}>

        <Desktop>
          <Box
            sx={{
              position: 'fixed',
              left: '20wmax',
              top: '15vh',
              zIndex: 5,
              padding: '10px',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                sx={{ marginBottom: '10px', width: '150px' }}
              />
            </LocalizationProvider>
            <TreeOrdersFilter onFilterChange={handleFilterChange} />

            <Coworking
              selectedOrder={lastClickedOrder}
              setSelectedOrder={(order) => setLastClickedOrder(order)}
            />

            <Discount
              selectedOrder={lastClickedOrder}
              setSelectedOrder={(order) => setLastClickedOrder(order)}
              updateTotalPrice={updateTotalPrice}
            />
            <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'center' }}>
              Total Prices: {totalSelectedOrdersPrice} {MAIN_CURRENCY}
            </Typography>
          </Box>

        </Desktop>
        <TabletAndBelow>
          <Button
            ref={anchorRef}
            variant="outlined"
            onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
            sx={{ marginTop: '10px', position: 'fixed', zIndex: '2' }}
          >
            {isMobileFilterOpen ? 'Close' : 'Open'}
          </Button>
          <Menu
            anchorEl={anchorRef.current}
            open={isMobileFilterOpen}
            onClose={() => setIsMobileFilterOpen(false)}
            PaperProps={{
              sx: {

                zIndex: 5,
                padding: '10px',
                backgroundColor: '#fff',
                borderRadius: '5px',
                display: isMobileFilterOpen ? 'block' : 'none',
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                sx={{ marginBottom: '10px', width: '150px' }}
              />
            </LocalizationProvider>
            <TreeOrdersFilter onFilterChange={handleFilterChange} />

            <Coworking
              selectedOrder={lastClickedOrder}
              setSelectedOrder={(order) => setLastClickedOrder(order)}
            />

            <Discount
              selectedOrder={lastClickedOrder}
              setSelectedOrder={(order) => setLastClickedOrder(order)}
              updateTotalPrice={updateTotalPrice}
            />
            <Button variant="outlined" onClick={() => setIsMobileFilterOpen(false)} sx={{ marginTop: '10px' }}>
              Close Filter
            </Button>
            <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'center' }}>
              Total Prices:  {totalSelectedOrdersPrice} {MAIN_CURRENCY}
            </Typography>
          </Menu>

        </TabletAndBelow>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'absolute',
            left: '15vw',
            top: '0',
            flexWrap: 'wrap',
          }}
          onClick={() => setSelectedOrders([])}
        >
          {filteredOrders.length ? (
            filteredOrders.map((order) => (
              <Paper
                key={order._id}
                elevation={3}
                sx={{
                  padding: 1,
                  margin: '10px',
                  width: '200px',
                  position: 'relative',
                  backgroundColor: selectedOrders.some((selectedOrder) => selectedOrder._id === order._id)
                    ? 'lightblue'
                    : 'white',
                }}
                onClick={(event) => handleOrderClick(event, order)}
              >
                {getUniqueDiscountNames(order.discounts).map((discountName, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      position: 'absolute',
                      top: `${index * 20}px`, // Adjust the top position based on the index
                      left: '0px',
                      padding: '2px 5px',
                      background: 'black',
                      opacity: '0.7',
                      color: 'white',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      borderRadius: '2px',
                    }}
                  >
                    {discountName} x{order.discounts.filter((d) => d.name === discountName).length}
                  </Typography>
                ))}
                <Typography
                  variant="body2"
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    padding: '2px 5px',
                    background: 'black',
                    opacity: '0.7',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    borderRadius: '2px',
                  }}
                >
                  {order.paid}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '15px',
                  }}
                >
                  <Typography variant="subtitle2" component="div">
                    Table: {order.tableNumber}
                  </Typography>
                  {order.clientName && (
                    <Typography variant="subtitle2" component="div">
                      Client: {order.clientName}
                    </Typography>
                  )}
                </Box>
                <List sx={{ maxHeight: '300px', overflow: 'auto' }}>
                  {order.dishes.length &&
                    order.dishes
                      .filter((dish, dishIdx) => order.dishesCancel[dishIdx] !== true)
                      .map((dish, dishIdx) => (
                        <ListItem key={dish._id} sx={{ marginBottom: '6px' }}>

                          <ListItemText
                            primary={dish.name}
                            secondary={`x ${order.dishesCount[dishIdx]}`}
                            secondaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                          />
                        </ListItem>
                      ))}
                </List>
                <Divider sx={{ margin: '8px 0' }} />
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Total: {order.totalPrice - order.discounts.reduce((total, discount) => total + discount.price, 0)} {MAIN_CURRENCY}
                </Typography>
                <ButtonGroup fullWidth>
                  <Button
                    sx={{
                      marginBottom: '8px',
                      transform: 'skew(-10deg)',
                      color: 'black',
                      borderColor: 'black',
                      zIndex: '3',
                      fontSize: '0.7rem',
                      '&:hover': {
                        color: 'white',
                        borderColor: 'black',
                        backgroundColor: 'black',
                      },
                    }}
                    variant="outlined"
                    onClick={() => handlePaid(order, 'Paid')}
                  >
                    PAID
                  </Button>
                  <Button
                    sx={{
                      marginBottom: '8px',
                      transform: 'skew(-10deg)',
                      color: 'black',
                      borderColor: 'black',
                      zIndex: '2',
                      fontSize: '0.7rem',
                      '&:hover': {
                        color: 'white',
                        borderColor: 'black',
                        backgroundColor: 'black',
                      },
                    }}
                    variant="outlined"
                    onClick={() => handlePaid(order, 'Sponsored')}
                  >
                    SPONS
                  </Button>
                  <Button
                    sx={{
                      marginBottom: '8px',
                      transform: 'skew(-10deg)',
                      color: 'black',
                      borderColor: 'black',
                      zIndex: '2',
                      fontSize: '0.7rem',
                      '&:hover': {
                        color: 'white',
                        borderColor: 'black',
                        backgroundColor: 'black',
                      },
                    }}
                    variant="outlined"
                    onClick={() => handlePaid(order, '')}
                  >
                    UNPAID
                  </Button>
                </ButtonGroup>
              </Paper>
            ))
          ) : (
            <Typography variant="body2">No orders found for the selected date.</Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default TreeOrders;

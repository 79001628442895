import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IDish } from "../../models/IDish";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from "react";
import UserService from "../../services/UserService";
import ExtrasDialog from './ExtrasDialog';


export default function EditDish({ dish, setDish, addTranslations }) {
  const [ingredient, setIngredient] = React.useState('');
  const [ingredientTranslation, setIngredientTranslation] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [categories, setCategories] = React.useState([]);
  const [extrasDialogOpen, setExtrasDialogOpen] = React.useState(false);

  const handleExtrasClick = () => {
    setExtrasDialogOpen(true);
    console.log(dish);
  };

  const handleExtrasChange = (updatedExtras) => {
    // Callback function to update the dish state with new extras
    setDish({ ...dish, extras: updatedExtras });
  };

  const handleChange = (prop: keyof IDish) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prop === 'isBlocked') {
      setDish({ ...dish, [prop]: event.target.checked });
    } else {
      setDish({ ...dish, [prop]: event.target.value });
    }
  };

  const addIngredient = () => {
    const { ingredients } = dish
    ingredients.push(ingredient)
    setIngredient('')
    setDish({ ...dish, ingredients })
  }

  const addIngredientTranslation = () => {
    const { ingredientsTranslation } = dish
    ingredientsTranslation.push(ingredientTranslation)
    setIngredientTranslation('')
    setDish({ ...dish, ingredientsTranslation })
  }

  const deleteIngredient = (index) => () => {
    const { ingredients } = dish;
    console.log('Deleting ingredient at index:', index);
    ingredients.splice(index, 1);
    setDish({ ...dish, ingredients });
    setIngredient('');
  };

  const deleteIngredientTranslation = (index) => () => {
    const { ingredientsTranslation } = dish;
    console.log('Deleting ingredient translation at index:', index);
    ingredientsTranslation.splice(index, 1);
    setDish({ ...dish, ingredientsTranslation });
    setIngredient('');
  };

  const updateCategory = (categoryId) => {
    setCategory(categoryId);
    setDish({ ...dish, category: categoryId });
  };

  const uploadImage = async (e) => {
    const blobFile = e.target.files[0];
    const formData = new FormData();
    formData.append("image", blobFile);
    await UserService.uploadImage(formData).then(response => {
      const image = response?.data?.fileName;
      setDish({ ...dish, image });
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  async function getCategories() {
    try {
      const response = await UserService.fetchCategories();
      setCategories(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box sx={{ m: 2 }}>
      <TextField
        fullWidth
        value={dish.name}
        onChange={handleChange('name')}
        label="Title"
        id="name"
        sx={{ mt: 1, mb: 1 }}
      />
      <TextField
        fullWidth
        value={dish.nameTranslation}
        onChange={handleChange('nameTranslation')}
        label="Title Translation"
        id="nameTranslation"
        sx={{ mt: 1, mb: 1 }}
      />
      <TextField
        fullWidth
        value={dish.image}
        onChange={handleChange('image')}
        label="Image URL"
        id="image"
        sx={{ mt: 1, mb: 1 }}
      />
      <label htmlFor="contained-button-file">
        <input onChange={uploadImage} style={{ display: 'none' }} accept="image/*"
          id="contained-button-file" type="file" />
        <Button sx={{ mt: 1, mb: 1, backgroundColor: '#1976D2' }} variant="contained" component="span">
          Upload Image
        </Button>
      </label>
      <TextField
        fullWidth
        value={dish.time}
        onChange={handleChange('time')}
        label="Time amount to do in seconds"
        id="time"
        type="number"
        sx={{ mt: 1, mb: 1 }}
      />
      <TextField
        fullWidth
        value={dish.price}
        onChange={handleChange('price')}
        label="Price"
        id="price"
        type="number"
        sx={{ mt: 1, mb: 1 }}
      />
      <TextField
        fullWidth
        value={dish.order}
        onChange={handleChange('order')}
        label="Order"
        id="order"
        type="number"
        sx={{ mt: 1, mb: 1 }}
      />
      <FormControlLabel sx={{ mt: 1, mb: 1 }}
        control={<Checkbox checked={dish.isBlocked} value={dish.isBlocked} onChange={handleChange('isBlocked')} />}
        label="Disable" />
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">Ingredients Translation:</Typography>
      {dish.ingredientsTranslation.length ?
        <Stack direction="row" sx={{ mt: 1, mb: 1, display: 'flex', flexWrap: 'wrap' }}>
          {dish.ingredientsTranslation.map((translation, index) => (
            <Chip key={index} sx={{ mr: 1, mb: 1 }} label={translation}
              onDelete={deleteIngredientTranslation(index)} />
          ))}
        </Stack> :
        <Typography variant={"body2"}>No ingredients translation, please add some</Typography>
      }
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Add new ingredient translation</InputLabel>
        <OutlinedInput
          value={ingredientTranslation}
          id="ingredient"
          onChange={(e) => setIngredientTranslation(e.target.value)}
          onKeyUp={(e) => e.key === 'Enter' && addIngredientTranslation()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={addIngredientTranslation}
                edge="end"
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Add new ingredient translation"
        />
      </FormControl>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">Ingredients:</Typography>
      {dish.ingredients.length ?
        <Stack direction="row" sx={{ mt: 1, mb: 1, display: 'flex', flexWrap: 'wrap' }}>
          {dish.ingredients.map((ingredient, index) => (
            <Chip key={index} sx={{ mr: 1, mb: 1 }} label={ingredient}
              onDelete={deleteIngredient(index)} />
          ))}
        </Stack> :
        <Typography variant={"body2"}>No ingredients, please add some</Typography>
      }
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Add new ingredient</InputLabel>
        <OutlinedInput
          value={ingredient}
          id="ingredient"
          onChange={(e) => setIngredient(e.target.value)}
          onKeyUp={(e) => e.key === 'Enter' && addIngredient()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={addIngredient}
                edge="end"
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Add new ingredient"
        />
      </FormControl>
      <Typography sx={{ mt: 1, mb: 1 }} variant={"h6"}>Current Category: {dish.category?.name}</Typography>
      <FormControl fullWidth>
        <InputLabel id="category">Category</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={category}
          label="Age"
          onChange={(e) => {
            updateCategory(e.target.value);
          }}
        >
          {categories.map((cat, index) => (<MenuItem key={index} value={cat._id}>{cat.name}</MenuItem>))}
        </Select>
      </FormControl>
      <Button
        onClick={handleExtrasClick}
        variant="outlined"
        sx={{ mt: 2 }}
      >
        Manage Extras
      </Button>
      {extrasDialogOpen && (
        <ExtrasDialog
          open={extrasDialogOpen}
          onClose={() => setExtrasDialogOpen(false)}
          dish={dish}
          onExtrasChange={handleExtrasChange} // Pass the callback function
        />
      )}
    </Box>
  );
}

import React, {useContext} from 'react';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "./Link";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import {PublicRoutes} from "../constants/PublicRoutes";
import {PrivateRoutes} from "../constants/PrivateRoutes";
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {Box} from '@mui/material';
import {Mobile, NotMobile,} from "../layout/Responsive";
import LanguageSwitchButton from './translate/LanguageSwitchButton';

const Bar = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate()
    const links = [{name: 'MANAGER', href: PrivateRoutes.MANAGER}, {
        name: 'KITCHEN',
        href: PrivateRoutes.KITCHEN
    }, {name: 'ADMIN', href: PrivateRoutes.ADMIN}]

    const logout = async () => {
        await store.logout()
        navigate(PublicRoutes.HOME_DIRECTORY, {replace: true})
    }

    // @ts-ignore
    return (
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                left: 0,
                right: 0,
                top: 0,
                background: 'radial-gradient(circle, rgba(148,187,233,1) 0%, rgba(238,174,202,0.6209077380952381) 100%)'
            }}
        >
        <Mobile>
            <Toolbar sx={{flexWrap: 'wrap', justifyContent: 'center',}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography fontFamily="LTRowena" variant="h3" color="inherit" noWrap sx={{flexGrow: 1}}>
                        Pasijou menu
                    </Typography>
                    <Typography fontFamily="LTRowena" variant="body1" color="inherit" noWrap sx={{flexGrow: 1}}>
                        coworking & food
                    </Typography>
                </Box>
                <LanguageSwitchButton />
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <nav>
                        {store.isAuth && links.map((l, i) => (<Link
                            variant="button"
                            color="text.primary"
                            to={l.href}
                            sx={{my: 1, mx: 1.5}}
                            key={i}
                        >
                            {l.name}
                        </Link>))}
                    </nav>
                    {
                        store.isAuth &&
                        <Button onClick={logout} variant="outlined" >
                            SIGN OUT
                        </Button>
                    }
                </Box>   
            </Toolbar>
        </Mobile>
        <NotMobile>
            <Toolbar sx={{flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography fontFamily="LTRowena" variant="h3" color="inherit" noWrap sx={{flexGrow: 1}}>
                        Pasijou menu
                    </Typography>
                    <Typography fontFamily="LTRowena" variant="body1" color="inherit" noWrap sx={{flexGrow: 1}}>
                        coworking & food
                    </Typography>
                </Box>
                <LanguageSwitchButton />
                <Box sx={{display: 'flex', alignItems: 'center',}}>
                    <nav>
                        {store.isAuth && links.map((l, i) => (<Link
                            variant="button"
                            color="text.primary"
                            to={l.href}
                            sx={{my: 1, mx: 1.5}}
                            key={i}
                        >
                            {l.name}
                        </Link>))}
                    </nav>
                    {
                        store.isAuth &&
                        <Button onClick={logout} variant="outlined" >
                            SIGN OUT
                        </Button>
                    }
                </Box>
            </Toolbar>  
        </NotMobile>
        </AppBar>
    );
}

export default Bar;
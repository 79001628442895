import React, { useState, ChangeEvent, useContext } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import UserService from '../../services/UserService';
import { IOrder, OrderStatus } from '../../models/IOrder';
import { Context } from '../../index';
import { IDish } from '../../models/IDish';

interface CoworkingProps {
  selectedOrder: IOrder | null;
  setSelectedOrder: (order: IOrder) => void;
}

const coworkingDishes = {
  CoWorking1000: {
    _id: '646ff703b9f48615cf28ba4a',
    name: 'CoWorking1000',
    time: 0,
    isBlocked: true,
    ingredients: [],
    order: 998,
    price: 1000,
  },
  CoWorking2000: {
    _id: '646ff77eb9f48615cf28ba4b',
    name: 'CoWorking2000',
    time: 0,
    isBlocked: true,
    order: 999,
    ingredients: [],
    price: 2000,
  },
};

const Coworking: React.FC<CoworkingProps> = ({ selectedOrder, setSelectedOrder }) => {
  const { store } = useContext(Context);
  const [selectedCoworkingType, setSelectedCoworkingType] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTableNumber, setSelectedTableNumber] = useState<number>(0);

  // Handle table number change
  const handleTableNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTableNumber(Number(event.target.value));
  };

  // Handle coworking type change
  const handleCoworkingTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCoworkingType(event.target.value);
  };

  // Create a dish based on coworking type
  const createDish = (coworkingType: string): IDish | undefined => {
    return coworkingDishes[coworkingType];
  };

  // Delete a dish from the selected order
  const deleteDishFromOrder = async (dishId: string) => {
    if (selectedOrder) {
      const dishIndex = selectedOrder.dishes.findIndex((dish) => dish._id === dishId);
      if (dishIndex !== -1) {
        const updatedDishesCount = [...selectedOrder.dishesCount];
        const dishCount = updatedDishesCount[dishIndex];
  
        if (dishCount > 0) {
          updatedDishesCount[dishIndex] = dishCount - 1;
  
          const updatedOrder = {
            ...selectedOrder,
            dishesCount: updatedDishesCount,
            totalPrice: selectedOrder.totalPrice - selectedOrder.dishes[dishIndex].price,
          };
  
          if (dishCount === 1) {
            updatedOrder.dishes = selectedOrder.dishes.filter((_, index) => index !== dishIndex);
            updatedOrder.dishesCancel = selectedOrder.dishesCancel.filter((_, index) => index !== dishIndex);
            updatedOrder.dishesDone = selectedOrder.dishesDone.filter((_, index) => index !== dishIndex);
          }
  
          try {
            await UserService.updateOrder(updatedOrder);
            console.log('Order updated successfully.');
            setSelectedOrder(updatedOrder);
          } catch (error) {
            console.error('Error updating order:', error);
          }
        }
      }
    }
  };
  

  // Create or update an order
const createOrder = async () => {
  const currentDate = new Date().toString();
  const coworking = createDish(selectedCoworkingType);

  if (coworking) {
    if (selectedOrder) {
      const coworkingIndex = selectedOrder.dishes.findIndex((dish) => dish._id === coworking._id);
      if (coworkingIndex !== -1) {
        const updatedDishesCount = [...selectedOrder.dishesCount];
        updatedDishesCount[coworkingIndex] += 1;

        const updatedOrder = {
          ...selectedOrder,
          dishesCount: updatedDishesCount,
          totalPrice: selectedOrder.totalPrice + coworking.price,
        };

        try {
          await UserService.updateOrder(updatedOrder);
          console.log('Order updated successfully.');
          setSelectedOrder(updatedOrder);
        } catch (error) {
          console.error('Error updating order:', error);
        }
      } else {
        const updatedOrder = {
          ...selectedOrder,
          dishes: [...selectedOrder.dishes, coworking],
          dishesCount: [...selectedOrder.dishesCount, 1],
          dishesCancel: [...selectedOrder.dishesCancel, false],
          dishesDone: [...selectedOrder.dishesDone, false],
          totalPrice: selectedOrder.totalPrice + coworking.price,
        };

        try {
          await UserService.updateOrder(updatedOrder);
          console.log('Order updated successfully.');
          setSelectedOrder(updatedOrder);
        } catch (error) {
          console.error('Error updating order:', error);
        }
      }
    } else {
      const order = {
        dishes: [coworking],
        dishesCount: [1],
        dishesCancel: [false],
        dishesDone: [false],
        totalPrice: coworking.price,
        discounts: [{ name: '', price: 0 }],
        clientName: '',
        comment: '',
        dishEndTimes: [null],
        manager: store.user.id,
        status: OrderStatus.ready,
        tableNumber: selectedTableNumber,
        createdAt: currentDate,
        paid: '',
      };

      try {
        await UserService.createOrder(order);
        console.log('Order created successfully.');
        setIsOpen(false);
        setSelectedOrder(order);
      } catch (error) {
        console.error('Error creating order:', error);
      }
    }
  }

  setSelectedCoworkingType('');
  setIsOpen(false);
};


  // Open the coworking order dialog
  const handleOpen = () => {
    setIsOpen(true);
  };

  // Close the coworking order dialog
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ position: 'relative',}}>
      {!isOpen ? (
        <Button sx={{ width:'100%'}} size="medium" variant="outlined" onClick={handleOpen}>
          Create Coworking
        </Button>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 2,
            backgroundColor: 'white',
            padding: '10px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Typography variant="h5">Coworking Order</Typography>
          <Box sx={{ mt: 2 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="coworking-type-label">Coworking Type</InputLabel>
              <Select
                labelId="coworking-type-label"
                id="coworking-type"
                value={selectedCoworkingType}
                onChange={handleCoworkingTypeChange}
              >
                <MenuItem value="CoWorking1000">CoWorking1000</MenuItem>
                <MenuItem value="CoWorking2000">CoWorking2000</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 2 }}>
            {selectedOrder ? (
              <Typography variant="body1">Table Number: {selectedOrder.tableNumber}</Typography>
            ) : (
              <TextField
                label="Table Number"
                type="number"
                value={selectedTableNumber}
                onChange={handleTableNumberChange}
              />
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            {selectedOrder ? (
              <>
                <Button sx={{ mb: 1, width:'100%' }} variant="outlined" onClick={createOrder}>
                  Update Order
                </Button>
                <Button
                  sx={{ mb: 1, width:'100%' }}
                  variant="outlined"
                  onClick={() => deleteDishFromOrder(coworkingDishes[selectedCoworkingType]._id)}
                >
                  Delete Coworking
                </Button>
              </>
            ) : (
              <Button sx={{ mb: 1, width:'100%' }} size="medium" variant="outlined" onClick={createOrder}>
                Create Order
              </Button>
            )}
            <Button sx={{ width:'100%' }} variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Coworking;

import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router-dom";
import Layout from "./layout/Layout";
import MainPage from "./pages/MainPage";
import SignInPage from "./pages/SignInPage";
import ProtectedPage from "./pages/ProtectedPage";
import RequireAuth from "./components/auth/RequireAuth";
import {PublicRoutes} from "./constants/PublicRoutes";
import SignUpPage from "./pages/SignUpPage";
import {PrivateRoutes} from "./constants/PrivateRoutes";
import AdminPage from "./pages/AdminPage/AdminPage";
import KitchenPage from "./pages/KitchenPage/KitchenPage";
import {Box, CircularProgress} from "@mui/material";
import ClientPage from "./pages/ClientPage/ClientPage";

const App: FC = () => {
    const {store} = useContext(Context);
    const [authChecked, setAuthChecked] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth().then(() => setAuthChecked(true))
        } else setAuthChecked(true)
    }, [store])

    if (store.isLoading) return <Box
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <CircularProgress/>
    </Box>

    if (authChecked)
        return <Routes>
            <Route element={<Layout/>}>
                <Route path={PublicRoutes.CLIENT_DIRECTORY} element={<ClientPage/>}/>
                {/*<Route path={PublicRoutes.ORDER_DIRECTORY} element={<OrderPage/>}/>*/}
                <Route path={PublicRoutes.LOGIN} element={<SignInPage/>}/>
                <Route path={PublicRoutes.REGISTRATION} element={<SignUpPage/>}/>
                <Route path={PublicRoutes.HOME_DIRECTORY} element={<MainPage/>}/>
                <Route
                    element={
                        <RequireAuth>
                            <AdminPage/>
                        </RequireAuth>
                    }
                    path={PrivateRoutes.ADMIN}
                />
                <Route
                    element={
                        <RequireAuth>
                            <ClientPage/>
                        </RequireAuth>
                    }
                    path={PrivateRoutes.MANAGER}
                />
                <Route
                    element={
                        <RequireAuth>
                            <KitchenPage/>
                        </RequireAuth>
                    }
                    path={PrivateRoutes.KITCHEN}
                />
                <Route
                    element={
                        <RequireAuth>
                            <ProtectedPage/>
                        </RequireAuth>
                    }
                    path="/protected"
                />
            </Route>
        </Routes>
};

export default observer(App);
